import ExperienceButton from "../../components/ExperienceButton";
import { Check, CheckBox, Done } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const FunctionPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box
          textAlign="center"
          marginTop={10}
          marginBottom={15}
          style={{ animation: "slide-up 1s ease-out" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // fontSize: "24px",
              fontFamily: "'Nanum Gothic'",
              fontWeight: "bold",
              color: "black",
              // fontStyle: "italic",
              lineHeight: "1.5",
              "@media (max-width: 800px)": {
                fontSize: "20px", //
              },
            }}
          >
            <span style={{ color: "  #0057af" }}>인가된 사용자</span>에게만 PC
            사용을 허가하여<br></br> PC 부정사용을 선제적으로{" "}
            <span style={{ color: "  #0057af" }}>방어</span>할 수 있습니다.
          </Typography>
          <ExperienceButton></ExperienceButton>
        </Box>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
          // padding: 5,
        }}
        // textAlign="center"
        marginTop={5}
        marginBottom={3}
        style={{ animation: "slide-little-up 1s ease-out" }}
      >
        {/* // ~~~~~~~~ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 5,
                marginTop: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: " #0057af" }}>생체 정보</span> 로그인
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                marginTop: 5,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check />
              인가된 사용자만이 생체 정보로 로그인할 수 있습니다.
              <br></br>
              <Check />
              비인가자의 비밀번호, PIN번호 접근시 강제 로그아웃(전원 종료)
              됩니다.
              <br></br>
              <Check />
              비인가자의 로그인 접근 정보 알람(이상 징후 탐지 기능) 및 PC 로그인
              리포트를 제공합니다.
              <br></br>
              <Check />
              모든(지문,홍채,지정맥 등) 생체 정보 Device를 적용 할 수 있습니다.
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 5,
                marginTop: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: "  #0057af" }}>2 Factor</span> 로그인(mOTP
              지원)
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                marginTop: 5,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check />
              1차 비밀번호 입력 로그인 후 2차 경고창에 OTP를 입력합니다
              <br></br>
              <Check />
              생체 정보 인식 장치를 구매하는 것이 부담되시거나<br></br>
              &nbsp;&nbsp;&nbsp;&nbsp; 도난,분실 파손 시 해결 방안이 될 수
              있습니다.
              <br></br>
              <Check /> 1차 비밀번호 입력없이 OTP만으로 로그인도 가능합니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/11page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        {/* // ~~~~~~~~ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 5,
                marginTop: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: " #004992" }}>NFC 모바일</span> 로그인
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                marginTop: 5,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check /> NFC 태그 접촉시 스마트폰 암호 또는 스마트폰 생체정보로
              로그인합니다.
              <br></br>
              <Check /> 보안은 강화하고 편리성을 높이는 방식
              <br></br>
              <Check /> RSU 독자 기술(특허) 사용
              <br></br>
              <Check />
              모든(지문,홍채,지정맥 등) 생체 정보 Device를 적용 할 수 있습니다.
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 5,
                marginTop: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: " #004992" }}>이상 징후 탐지</span> 기능
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                marginTop: 5,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check /> 근무시간 외, 야간, 주말, 휴일 등에 탈취 시도를 방어하고
              <br></br> 실시간 로그인 감시를 통해 PC사용을 원천적으로 차단하고
              정보 유출을 사전 방지합니다.
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 5,
                marginTop: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: " #004992" }}>관리자</span> 기능
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                marginTop: 5,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check /> 관리자 페이지,앱 을 통해 사용자의 로그인,로그아웃 시간,
              접속IP 등 접속 기록을 추적합니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/12page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정

              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        {/* // ~~~~~~~~~ */}
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          타 솔루션과의 특징 : 다양한 방식{" "}
          <span style={{ color: "  #0057af" }}>동시 지원</span>
        </Typography>

        {/* 시작 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginTop: 10,
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              다양한 인증 방식(인증 솔루션) 제공
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check /> 기존 Passwordless 방식 로그인과 함께 2Factor 방식
              로그인을 동시에 지원하여 보안을 강화하고 편리성이 증대되었습니다.
              <br></br>
              <br></br>
              <Check /> MS의 Win Hello 기술을 적용하여 별도의 안면인식 솔루션을
              필요로 하지 않아 비용절감이 가능합니다.
              <br></br>
              <br></br>
              <Check /> 타사의 인증 솔루션 적용이 가능하여 다양한 인증방식을
              제공할 수 있는 유연하고 탄력적인 솔루션입니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/6page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        {/* 끝 */}

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          타 솔루션 <span style={{ color: " #0057af" }}>확장</span>가능
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginTop: 10,
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              여러 솔루션 적용 가능한{" "}
              <span style={{ color: " #0057af" }}>플랫폼</span>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check /> 기존 PC 로그인 솔루션들은 다른 솔루션들을 이용하지
              못했습니다. 그로인해 기존 생체인증이 고장 시 대안이 없었습니다.
              <br></br><Check />
              LogVisor는 지문 솔루션,안면인식 솔루션,지정맥 솔루션, OTP 솔루션
              등 다양한 솔루션을 통합 이용가능한 플랫폼입니다.
              <br></br><Check />
              기존 생체인증 고장 시 대안으로 OTP 인증으로 추가 인증이
              가능합니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/7page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          <span style={{ color: " #0057af" }}>랜섬웨어 </span>방어 기능
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              {/* 랜섬웨어 방지 기능 */}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              <Check />
              AD 환경에서 ID & PW 해킹 시 PC 로그인 불가로 랜섬웨어 시도가
              불가능합니다.
              <br></br>
              <br></br>
              <Check />
              블록체인 기술을 적용하여 데이터의 위조를 방지합니다.
              <br></br>
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/13page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default FunctionPage;
