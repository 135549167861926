import React from "react";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import {  signInTest } from "../../redux/manager/managerActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MUILink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const SignInPage_Test: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const manager = useSelector((state: RootState) => state.manager); // Get the current user state

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const managerLoginData = {
      managerId: data.get("managerId") as string,
      password: data.get("password") as string,
    };

    console.log("signin-handleSubmit메서드 작동");
    console.log("userData:", managerLoginData);
    await dispatch(signInTest(managerLoginData, navigate));
    console.log("manager.isLoggedIn:", manager.isLoggedIn);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ minWidth: 228 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginBottom: "30px",
          }}
        >
          <Typography component="h1" variant="h5">
            TEST 로그인( POST 방식)
          </Typography>
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 8 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="managerId"
            label="ID"
            name="managerId"
            autoComplete="id"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            로그인
          </Button>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <MUILink href="/test/sign-up" variant="body2">
                {"TEST 회원가입"}
              </MUILink>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <MUILink href="/test/sign-in-get" variant="body2">
                {"GET방식 로그인"}
              </MUILink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignInPage_Test;
