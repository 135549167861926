import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel 스타일을 적용하기 위한 CSS 파일
import "./mainPage.css";
import { Box, Button, CardMedia, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExperienceButton from "../../components/ExperienceButton";

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginBottom: 10 }}
        >
          <Box
            textAlign="center"
            marginTop={10}
            marginBottom={3}
            // style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                color: "black",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              멀티인증(생체,OTP,NFC,Bluetooth)이 가능한<br></br> 블록체인 기반의
              PC 로그인 보안 솔루션<br></br>
              <span style={{ color: "#004992", marginRight: "" }}>
                {" "}
                카인드 로그바이저{" "}
              </span>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                color: "grey",
                lineHeight: "1.5",
                // marginBottom: 50,
              }}
            >
              위험에 노출된 당신의 PC로그인 보안 대책<br></br>
              <span style={{ color: "#004992" }}>KIND LogVisor</span>가 그
              해답을 드립니다.
            </Typography>
            <ExperienceButton></ExperienceButton>
          </Box>
        </Box>
      </Container>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          className="container"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "@media (max-width: 1000px)": {
              flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
            },
            // mb: -15,
          }}
        >
          <CardMedia
            component="img"
            image="/images/main/intro2.png"
            alt="image-r2"
            sx={{
              maxWidth: 500,
              // maxWidth: "40%",
              //  height:"auto",
              objectFit: "contain",
              "@media (max-width: 1000px)": {
                order: 2, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                marginBottom: 8,
              },
            }}
          />
          <CardMedia
            component="img"
            image="/images/main/logvisor.jpg"
            alt="image-logvisor"
            sx={{
              maxWidth: 750,
              // maxWidth: "55%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1000px)": {
                order: 1, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                marginBottom: 5,
              },
            }}
          />
        </Box>

        {/* <CardMedia
          component="img"
          image="/images/main/main-r4.png"
          alt="image-r4"
          sx={{ mb: 0, p: 15 }}
        /> */}
        <CardMedia
          component="img"
          image="/images/main/main-r5.png"
          alt="image-r5"
          sx={{ mb: 15, p: 0 }}
        />
        <Box
          className="container"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <CardMedia
            component="img"
            image="/images/main/main-r3.png"
            alt="image-r3"
            sx={{ mb: 15, p: 0 }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MainPage;
