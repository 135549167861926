import {
  FETCH_USERLIST_FAILURE_TEMP,
  FETCH_USERLIST_REQUEST_TEMP,
  FETCH_USERLIST_SUCCESS_TEMP,
  FETCH_USER_FAILURE_TEMP,
  FETCH_USER_REQUEST_TEMP,
  FETCH_USER_SUCCESS_TEMP,
} from "../../types/userTypes_temp";
import { UserActionTypes, UserState } from "../../types/userTypes_temp";

const initialState: UserState = {
  loading: false,
  error: null,
  userTemp: null,
  userListTemp: [],
  userDetailLoginDataTemp: [],
};

export const userReducerTemp = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case FETCH_USERLIST_REQUEST_TEMP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERLIST_SUCCESS_TEMP:
      return {
        ...state,
        loading: false,
        userListTemp: action.payload,
        error: "",
      };
    case FETCH_USERLIST_FAILURE_TEMP:
      return {
        ...state,
        loading: false,
        userListTemp: [],
        error: action.payload,
      };
    case FETCH_USER_REQUEST_TEMP:
      return { ...state, loading: true, error: null };
    case FETCH_USER_SUCCESS_TEMP:
      return { ...state, loading: false, userDetailLoginDataTemp: action.payload };
    case FETCH_USER_FAILURE_TEMP:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

export default userReducerTemp;
