import React from "react";
import { Box, Typography, Grid, Container, Divider } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

interface FooterLinkProps extends LinkProps {
  to: string;
}

const FooterLink: React.FC<FooterLinkProps> = ({ to, children }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: "white",
        display: "block",
        marginBottom: "8px", // 각 링크 사이에 간격 추가
      }}
    >
      <Typography
        component="span"
        sx={{
          fontFamily: "'Nanum Gothic'", // 글꼴 변경
          // fontSize: "1rem",
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#003366",
        color: "white",
        mt: 5,
        minHeight: "350px", // 높이 설정
        display: "flex", // Flexbox를 사용
        flexDirection: "column", // 세로 방향 배열
        justifyContent: "center",
      }}
    >
      <Box sx={{ py: 4, minWidth: 261 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0,
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                제품 소개
              </Typography>
              <Divider
                sx={{
                  bgcolor: "white",
                  borderWidth: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/introduce/logvisor">LogVisor</FooterLink>
              <FooterLink to="/introduce/function">주요 기능</FooterLink>
              <FooterLink to="/introduce/competitive">경쟁력</FooterLink>
              <FooterLink to="/introduce/video">제품소개 영상</FooterLink>
              <FooterLink to="/introduce/experience">체험하기</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              {" "}
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                요금제
              </Typography>
              <Divider
                sx={{
                  bgcolor: "white",
                  borderWidth: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/payment-system">요금제 비교</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                관리
              </Typography>
              <Divider
                sx={{
                  bgcolor: "white",
                  borderWidth: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/user-register">사원 등록</FooterLink>
              <FooterLink to="/user-list/1">로그 조회</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                문의
              </Typography>
              <Divider
                sx={{
                  bgcolor: "white",
                  borderWidth: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/technical-board-register">기술 문의</FooterLink>
              <FooterLink to="/payment-board-register">결제 문의</FooterLink>
            </Box>
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid textAlign="right">
              <img
                src="/images/footer/bottom-logo.png"
                alt="Logo"
                style={{ maxWidth: "100px", marginBottom: 20 }}
              />
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Nanum Gothic'", mt: 1 }}
              >
                (주)카인드소프트
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                }}
              >
                대표자 : 한규명 | 사업자 등록번호 : 452-81-02149
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                }}
              >
                경기도 수원시 영통구 광교중앙로 170, A동 1707, 1708호
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                }}
              >
                T 031 308 7272 | F 0303 3444 7272
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                }}
              >
                개인정보보호책임자 : 진은수 (kind@kind-soft.com)
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
