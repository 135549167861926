// ProductCard.tsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Manager } from "../../types/managerTypes";
import { AppDispatch } from "@/index";
import { useDispatch } from "react-redux";
import {
  deleteManager,
  fetchManagerList,
  pwInitializationManager,
} from "../../redux/manager/managerActions";

interface ManagerCardProps {
  manager: Manager;
}

const ManagerCardForm: React.FC<ManagerCardProps> = ({ manager }) => {
  const navigate = useNavigate();
  const dispatch1: AppDispatch = useDispatch();
  const [selectedManagerId, setSelectedManagerId] = useState<string | null>(
    null
  );
  const [selectedManagerIdOfPw, setSelectedManagerIdOfPw] = useState<string | null>(
    null
  );

  // PW 초기화 다이얼로그 열기
  const handleOpenPwInitializationDialog = (managerId: string) => {
    setSelectedManagerIdOfPw(managerId);
  };

  //비번초기화
  const handlePwInitialization = () => {
    if (selectedManagerIdOfPw) {
      dispatch1(pwInitializationManager(selectedManagerIdOfPw))
        .then(() => {
          setSelectedManagerIdOfPw(null); // 다이얼로그 닫기
        })
        .catch((error: any) => {
          console.error("Error while pwInitializationManager:", error);
        });
    }
  };

  //수정버튼 클릭
  const handleUpdateClick = (managerId: string) => {
    navigate(`/manager-update/${managerId}`);
  };


 // 삭제 확인 다이얼로그 열기
 const handleDeleteConfirm = (managerId: string) => {
  setSelectedManagerId(managerId);
};
 
  //삭제버튼 클릭
  const handleDeleteClick = () => {
    if (selectedManagerId) {
      dispatch1(deleteManager(selectedManagerId))
        .then(() => {
          dispatch1(fetchManagerList());
          setSelectedManagerId(null); // 다이얼로그 닫기
          navigate(`/manager-list`);
        })
        .catch((error: any) => {
          console.error("Error while deleting manager:", error);
        });
    }
  };

  return (
    <>
      <Card
        sx={{
          minWidth: "180px",
          maxHeight: "140px",
          width: "250px",
          margin: 2,
          borderRadius: 4,
        }}
        key={manager.managerId}
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box sx={{}}>
              <Typography
                gutterBottom
                variant="h5"
                component="span"
                sx={{ fontSize: 20 }}
              >
                {manager.managerId}
              </Typography>
            </Box>
            <Box sx={{}}>
              <IconButton
                onClick={() => {
                  handleOpenPwInitializationDialog(manager.managerId);
                }}
                size="small"
                style={{ color: "rgb(0, 153, 0)" }} // 초록색 또는 원하는 색상
              >
                <VpnKeyIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleUpdateClick(manager.managerId);
                }}
                size="small"
                style={{ color: "rgb(0, 0, 197)" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleDeleteConfirm(manager.managerId);
                }}
                size="small"
                style={{ color: "rgb(197, 0, 0)" }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body1" component="span">
              {manager.company}
            </Typography>
            <Typography variant="body2" component="span">
              {manager.crn}
            </Typography>
            <Typography variant="body2" component="span">
              {manager.email}
            </Typography>
            <Typography variant="body2" component="span">
              {manager.tel}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={Boolean(selectedManagerId)}
        onClose={() => setSelectedManagerId(null)}
      >
        <DialogTitle>삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>{`'${selectedManagerId}' 관리자를 삭제하겠습니까?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="primary">
            네
          </Button>
          <Button
            onClick={() => setSelectedManagerId(null)}
            color="secondary"
            autoFocus
          >
            아니오
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(selectedManagerIdOfPw)}
        onClose={() => setSelectedManagerIdOfPw(null)}
      >
        <DialogTitle>비밀번호 초기화</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`'${selectedManagerIdOfPw}' 관리자의 비밀번호를 초기화하시겠습니까?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwInitialization} color="primary">
            네
          </Button>
          <Button
              onClick={() => setSelectedManagerIdOfPw(null)}
            color="secondary"
            autoFocus
          >
            아니오
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManagerCardForm;
