import React, { useState } from "react";
import { Link, useMatch, useNavigate, useLocation } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IconButton,
  List,
  ListItemText,
  ListItemIcon,
  Collapse,
  Typography,
  ListItemButton,
} from "@mui/material";
import ActiveNavLink from "./ActiveNavLink";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/redux/reducers";
import { logout } from "../redux/manager/managerActions";
import { AppDispatch } from "../index";
import "./Navbar.css";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const Navbar: React.FC = () => {
  const Manager = useSelector((state: RootState) => state.manager);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:900px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const justifyContentClass = isMobile
    ? "justify-content-between"
    : "justify-content-evenly";

  //문의탭 드랍다운 관리
  // 드롭다운 상태 관리
  const [openInquiry, setOpenInquiry] = useState(false);
  // 드롭다운 토글 함수
  const handleInquiryClick = () => {
    setOpenInquiry(!openInquiry);
  };

  //관리 드랍다운 관리
  // 드롭다운 상태 관리
  const [openManagement, setOpenManagement] = useState(false);
  // 드롭다운 토글 함수
  const handleInquiry1Click = () => {
    setOpenManagement(!openManagement);
  };

  //제품소개 드랍다운 관리
  // 드롭다운 상태 관리
  const [openIntroduce, setOpenIntroduce] = useState(false);
  // 드롭다운 토글 함수
  const handleIntroduceClick = () => {
    setOpenIntroduce(!openIntroduce);
  };

  // '기술 지원' 또는 '결제 문의' 페이지와 일치하는지 확인
  const isTechnicalBoardActive = useMatch("/technical-board-register");
  const isPaymentActive = useMatch("/payment-board-register");

  const isRegisterActive = useMatch("/user-register");
  // const isLogActive = location.pathname.startsWith("/user-list/");
  const isLogActive = useMatch("/user-list/1");

  const isLogvisorActive = useMatch("/introduce/logvisor");
  const isFunctionActive = useMatch("/introduce/function");
  const isVideoActive = useMatch("/introduce/video");
  const isExperienceActive = useMatch("/introduce/experience");

  // '문의' 탭의 스타일을 결정
  const inquiryStyle =
    isTechnicalBoardActive || isPaymentActive
      ? { color: "#1976d2", fontWeight: "bold" }
      : { fontWeight: "bold" };

  const managementStyle =
    isRegisterActive || isLogActive
      ? { color: "#1976d2", fontWeight: "bold" }
      : { fontWeight: "bold" };

  const introduceStyle =
    isLogvisorActive || isFunctionActive || isVideoActive || isExperienceActive
      ? { color: "#1976d2", fontWeight: "bold" }
      : { fontWeight: "bold" };

  const handleLogout = (event: React.MouseEvent<any, MouseEvent>) => {
    event.preventDefault();
    dispatch(logout());
    navigate("/");
    setDrawerOpen(false);
  };
  console.log("NavBar 렌더링!!");

  return (
    <nav
      className="navbar navbar-custom"
      style={{
        height: "70px",
        minWidth: "300px",
        // borderBottom: "1px solid lightgrey",
      }}
    >
      <div className="container-fluid">
        <div
          className={`d-flex ${justifyContentClass} w-100 align-items-center`}
        >
          {/* 로고 */}
          <ul className="navbar-nav   d-flex flex-row justify-content-between align-items-center">
            <li className="nav-item" style={{ marginLeft: "" }}>
              <Link className="navbar-brand" to="/">
                <img
                  src="/images/main/logvisor-big-removebg-preview.png"
                  alt="Logo"
                  style={{ width: "150px" }}
                />
              </Link>
            </li>
          </ul>
          {/* 중간 메뉴 */}
          {!isMobile && (
            <>
              <ul className="navbar-nav   d-flex flex-row justify-content-between  align-items-center">
                <li
                  className="nav-link nav-item nav-item1 dropdown simple-dropdown "
                  style={{ width: "", textAlign: "center" }}
                >
                  <span
                    className="h6"
                    style={{
                      ...introduceStyle,
                      fontFamily: "'Nanum Gothic', sans-serif",
                      fontSize: "17px",
                    }}
                  >
                    제품 소개
                  </span>

                  <ul
                    className="dropdown-menu"
                    role="menu"
                    style={{ textAlign: "center" }}
                  >
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/introduce/logvisor"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          LogVisor
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/introduce/function"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          주요 기능
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/introduce/competitive"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          경쟁력
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/introduce/video"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          제품소개 영상
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/introduce/experience"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          체험하기
                        </span>
                      </ActiveNavLink>
                    </li>
                  </ul>
                </li>

                <li
                  className="nav-item"
                  style={{ width: "", textAlign: "center" }}
                >
                  <ActiveNavLink
                    to="/payment-system"
                    activeClassName="active-link"
                    className="nav-link"
                  >
                    <span
                      className="h6"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "'Nanum Gothic'",
                        fontSize: "17px",
                      }}
                    >
                      요금제
                    </span>
                  </ActiveNavLink>
                </li>

                <li
                  className="nav-link nav-item nav-item1 dropdown simple-dropdown "
                  style={{ width: "", textAlign: "center" }}
                >
                  <span
                    className="h6"
                    style={{
                      ...managementStyle,
                      fontFamily: "'Nanum Gothic', sans-serif",
                      fontSize: "17px",
                    }}
                  >
                    관리
                  </span>

                  <ul
                    className="dropdown-menu"
                    role="menu"
                    style={{ textAlign: "center" }}
                  >
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/user-register"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          사원 등록
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/user-list/1"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          로그 조회
                        </span>
                      </ActiveNavLink>
                    </li>
                  </ul>
                </li>

                <li
                  className="nav-link nav-item nav-item1 dropdown simple-dropdown "
                  style={{ width: "", textAlign: "center" }}
                >
                  <span
                    className="h6"
                    style={{
                      ...inquiryStyle,
                      fontFamily: "'Nanum Gothic', sans-serif",
                      fontSize: "17px",
                    }}
                  >
                    문의
                  </span>

                  <ul
                    className="dropdown-menu"
                    role="menu"
                    style={{ textAlign: "center" }}
                  >
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/technical-board-register"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          기술 문의
                        </span>
                      </ActiveNavLink>
                    </li>
                    <li className="dropdown">
                      <ActiveNavLink
                        to="/payment-board-register"
                        activeClassName="active-link"
                        className="nav-link"
                      >
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                            fontSize: "16px",
                          }}
                        >
                          결제 문의
                        </span>
                      </ActiveNavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* 로그인 회원가입부분 */}
              <ul
                className="navbar-nav d-flex flex-row  justify-content-between align-items-center "
              >
                <li
                  className="nav-item"
                  style={{
                    width: "",
                    textAlign: "center",
                    marginRight: "",
                  }}
                >
                  <ActiveNavLink
                    className="nav-link"
                    activeClassName="active-link"
                    to="/payment-board-register"
                  >
                    <div className="" style={{ textAlign: "center" }}>
                      <span
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                        }}
                      >
                        구축형 문의
                      </span>
                    </div>
                  </ActiveNavLink>
                </li>

                <li
                  className="nav-item"
                  style={{
                    width: "",
                    textAlign: "center",
                    marginRight: "",
                  }}
                >
                  {Manager.isLoggedIn ? (
                    <ActiveNavLink
                      className="nav-link"
                      activeClassName="active-link"
                      to={`/manager-update/`}
                    >
                      <div className="">
                        <AccountCircleIcon className="mx-2" />
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                          }}
                        >
                          내 정보
                        </span>
                      </div>
                    </ActiveNavLink>
                  ) : (
                    <ActiveNavLink
                      className="nav-link"
                      activeClassName="active-link"
                      to="/sign-in"
                    >
                      <div style={{ textAlign: "center" }}>
                        <ExitToAppIcon className="mx-2" />
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                          }}
                        >
                          로그인
                        </span>
                      </div>
                    </ActiveNavLink>
                  )}
                </li>
                <li className="nav-item" style={{ width: "" }}>
                  {Manager.isLoggedIn ? (
                    <button onClick={handleLogout} className="nav-link">
                      <ExitToAppIcon className="mx-2" />
                      <span
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                        }}
                      >
                        로그아웃
                      </span>
                    </button>
                  ) : (
                    <ActiveNavLink
                      className="nav-link"
                      activeClassName="active-link"
                      to="/sign-up"
                    >
                      <div className="" style={{ textAlign: "center" }}>
                        <PersonAddIcon className="mx-2" />
                        <span
                          className="h6"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "'Nanum Gothic'",
                          }}
                        >
                          무료체험
                        </span>
                      </div>
                    </ActiveNavLink>
                  )}
                </li>
              </ul>
            </>
          )}
          {isMobile && (
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              width: "100%",
            },
          }}
          ModalProps={{
            BackdropProps: {
              style: {
                // backgroundColor: 'transparent', // 배경을 투명하게 설정합니다.
              },
            },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px",
              height: "70px",
              cursor: "pointer", // 이 부분을 추가합니다.
            }}
            onClick={() => setDrawerOpen(false)}
          >
            <img
              src="/images/logvisor-1.png"
              alt="Logo"
              style={{ width: "150px" }}
            />

            <IconButton>
              <CloseIcon />
            </IconButton>
          </div>
          <List>
            {/* <ListItemButton
              component={Link}
              to="/"
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    제품 소개
                  </Typography>
                }
              />
            </ListItemButton> */}

            <ListItemButton onClick={handleIntroduceClick}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    제품 소개
                  </Typography>
                }
              />
              {openIntroduce ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={openIntroduce} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/introduce/logvisor"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        LogVisor
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/introduce/function"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        주요기능
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/introduce/competitive"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        경쟁력
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/introduce/video"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        제품소개영상
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/introduce/experience"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        체험하기
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton
              component={Link}
              to="/payment-system"
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    요금제
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={handleInquiry1Click}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    관리
                  </Typography>
                }
              />
              {openManagement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={openManagement} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/user-register"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        사원 등록
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/user-list/1"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        로그 조회
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleInquiryClick}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    문의
                  </Typography>
                }
              />
              {openInquiry ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={openInquiry} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/technical-board-register"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        기술 문의
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/payment-board-register"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: "gray",
                          fontWeight: 600,
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        결제 문의
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton
              component={Link}
              to="/payment-board-register"
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Nanum Gothic'",
                      fontSize: "17px",
                    }}
                  >
                    구축형 문의
                  </Typography>
                }
              />
            </ListItemButton>
            {/* 로그인 상태에 따른 조건부 렌더링 */}
            {Manager.isLoggedIn ? (
              <>
                <ListItemButton
                  component={Link}
                  to="/manager-update"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "17px",
                        }}
                      >
                        내 정보
                      </Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "17px",
                        }}
                      >
                        로그아웃
                      </Typography>
                    }
                  />
                </ListItemButton>
              </>
            ) : (
              <>
                <ListItemButton
                  component={Link}
                  to="/sign-in"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "17px",
                        }}
                      >
                        로그인
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/sign-up"
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        className="h6"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "'Nanum Gothic'",
                          fontSize: "17px",
                        }}
                      >
                        무료 체험
                      </Typography>
                    }
                  />
                </ListItemButton>
              </>
            )}
          </List>
        </Drawer>
      </div>
    </nav>
  );
};
export default Navbar;
