import { Box, Typography } from "@mui/material";
import PaymentBoardRegisterForm from "../../components/paymentBoard/paymentBoardRegisterForm";
import React from "react";
import { grey } from "@mui/material/colors";
import "./paymentBoardRegister.css";
const PaymentBoardRegisterPage: React.FC = () => {
  console.log(" PaymentBoardRegisterPage 렌더링");
  return (
    <>
      <Box
        sx={{
          backgroundColor: grey[200],
          width: "100vw",
          // height: "50vh",
          height: "400px",
          zIndex: -1,
          position: "relative",

          minWidth: "320px",
        }}
      >
        <Typography
          variant="h5"
          color="textSecondary"
          component="p"
          style={{
            // textAlign: "right",
            // fontSize: "25px",
            fontWeight: "bold",

            whiteSpace: "pre",
            animation: "slide-up 1s ease-out", // 애니메이션 적용
            fontFamily: "'Nanum Gothic'", // 글꼴 적용
          }}
          sx={{
            padding: { xs: "30px", sm: "70px", md: "100px", lg: "100px" },
            fontSize: {
              xs: "1.0rem",
              sm: "1.0rem",
              md: "1.0rem",
              lg: "1.5rem",
              "@media (max-width:684px)": "2rem",
            },
            // fontSize: "1.5rem", // 기본 글자 크기
            // "@media (max-width:840px)": {
            // fontSize: "1.0rem", // 화면 너비가 600px 이하일 때 글자 크기
            // },
          }}
        >
          계좌 번호
          <br />
          <br />
          <span style={{ display: "block", margin: "10px 5px" }}>
            1005-204-204566 우리은행 (주)카인드소프트
          </span>
          <span style={{ display: "block", margin: "10px 5px" }}>
            944-031674-04-016 기업은행 (주)카인드소프트
          </span>
          <span style={{ display: "block", margin: "10px 5px" }}>
            100-035-747695 신한은행 (주)카인드소프트
          </span>
        </Typography>

        <img
          src="/images/payment-board/ci2.jpg" // 여기에 이미지 URL을 넣으세요
          alt="Descriptive Alt Text"
          className="hide-on-mobile"
          style={{
            position: "absolute", // 절대적 위치 설정
            right: 100, // 오른쪽에 위치
            bottom: 20, // 아래쪽에 위치
            zIndex: -1,
            maxWidth: "500px", // 이미지 최대 너비 설정
            maxHeight: "500px", // 이미지 최대 높이 설정
          }}
        />
      </Box>
      <div className="container mt-3 p-5">
        <PaymentBoardRegisterForm />
      </div>
    </>
  );
};

export default PaymentBoardRegisterPage;
