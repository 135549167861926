import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { PaymentBoardList } from "../../types/paymentBoardTypes";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../Pagination";
import SearchIcon from "@mui/icons-material/Search";
interface PaymentBoardListFormProps {
  paymentBoardList: PaymentBoardList[];
}
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp,
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      // border: "1px solid lightgrey",
      textAlign: "center",
      // padding:"15px"
      fontFamily: isHeader ? "'Nanum Gothic'" : undefined, // isHeader가 true일 때만 글꼴 적용
      fontSize: isHeader ? "16px" : undefined, // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader ? "bold" : undefined, // isHeader가 true일 때만 글자 굵기 적용
    }}
  >
    {children}
  </TableCell>
);
const PaymentBoardListForm: React.FC<PaymentBoardListFormProps> = ({
  paymentBoardList,
}) => {
  const { page } = useParams<{ page: string }>(); // URL에서 페이지 번호 추출
  const navigate = useNavigate();

  const [filteredPaymentBoardList, setFilteredPaymentBoardList] =
    useState<PaymentBoardList[]>(paymentBoardList);
      // URL에서 가져온 페이지 번호를 정수로 변환하고, 유효하지 않으면 1로 설정
  const initialPage = parseInt(page || "1", 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const pagesPerGroup = 10; //한페이지에 나오는 버튼수
  const itemsPerPage = 12; //한페이지에 나오는 아이템수


  let numberOfPages = Math.ceil(paymentBoardList.length / itemsPerPage);
  let maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);

  useEffect(() => {
    // URL의 페이지 번호가 변경될 때마다 현재 페이지 상태 업데이트
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    // userList가 업데이트될 때마다 filteredUserList도 업데이트
    setFilteredPaymentBoardList(paymentBoardList);
  }, [paymentBoardList]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filterPaymentBoardList = (searchValue: any) => {
    const filtered = paymentBoardList.filter(
      (paymentBoard: PaymentBoardList) => {
        if (
          searchValue &&
          !(
            paymentBoard.companyName.includes(searchValue) ||
            paymentBoard.managerName.includes(searchValue) ||
            paymentBoard.inquiryDetail.includes(searchValue)
          )
        )
          return false;
        return true;
      }
    );
    setFilteredPaymentBoardList(filtered);
  };

  const executeSearch = () => {
    setCurrentPage(1);
    setCurrentPageGroup(1);
    const searchValue = searchInputRef.current?.value;
    filterPaymentBoardList(searchValue);
  };

  // 엔터 키를 눌렀을 때 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      executeSearch();
    }
  };
  const handleSearchClick = () => {
    executeSearch();
  };
  const handleResetList = () => {
    setFilteredPaymentBoardList(paymentBoardList); // 기술 문의 목록을 초기 상태로 재설정
    if (searchInputRef.current) {
      searchInputRef.current.value = ""; // 검색 입력 필드를 비웁니다.
    }
    setCurrentPage(1);
    setCurrentPageGroup(1);
  };
  const sortedPaymentBoardList = filteredPaymentBoardList;

  numberOfPages = Math.ceil(sortedPaymentBoardList.length / itemsPerPage);
  maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);

  const displayedPaymentBoardList = sortedPaymentBoardList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const pageNumbers = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1
  ).slice(
    (currentPageGroup - 1) * pagesPerGroup, // 은 현재 페이지 그룹의 시작 페이지 번호를 계산
    currentPageGroup * pagesPerGroup // 현재 페이지 그룹의 마지막 페이지 번호
    //이는 다음 페이지 그룹의 시작 번호와 동일하므로, 실제로는 이 번호보다 하나 작은 페이지까지가 현재 페이지 그룹에 포함됩니다.
  );

  //로우 클릭
  const handleRowClick = (paymentBoardId: string) => {
    navigate(`/payment-board-detail/${paymentBoardId}`); // 상세 페이지로 이동
  };
  console.log("paymentBoardListForm렌더링");

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // flexWrap="wrap"
        // justifyContent="space-between"
        // p={0}
        // mx={0}
        // my={0}
        sx={{}}
      >
        <Box>
          <span
            className="h3"
            style={{
              fontWeight: "bold",
              fontFamily: "'Nanum Gothic'",
              color: "black",
              // fontSize: "17px",
            }}
          >
            결제 문의
          </span>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search name or company"
            variant="outlined"
            inputRef={searchInputRef}
            onKeyDown={handleKeyDown}
            sx={{ minWidth: 200, width: 350 }}
          />
          <Button
            variant="outlined"
            onClick={handleSearchClick} // 검색 버튼 클릭
            sx={{ minWidth: "fit-content", height: 55 }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            marginBottom: "30px",
            marginTop: "30px",
            // textOverflow: "ellipsis",
            // overflow: "hidden",
            // whiteSpace: "nowrap",
          }}
        >
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead sx={{ backgroundColor: "grey.200" }}>
              <TableRow>
                <TableCellWithOverflow widthProp="6%" isHeader>
                  글번호
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="40%" isHeader>
                  글 내용
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%" isHeader>
                  회사명
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%" isHeader>
                  부서
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="7%" isHeader>
                  관리자명
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="10%" isHeader>
                  관리자ID
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="8%" isHeader>
                  작성일
                </TableCellWithOverflow>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedPaymentBoardList.map((paymentBoard, index) => (
                <TableRow
                  key={paymentBoard.paymentBoardId}
                  hover // 마우스 오버 스타일
                  onClick={() => handleRowClick(paymentBoard.paymentBoardId)}
                  style={{ cursor: "pointer" }} // 마우스 포인터 변경
                >
                  <TableCellWithOverflow widthProp="6%">
                    {paymentBoard.paymentBoardId}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="40%">
                    {paymentBoard.inquiryDetail}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="12%">
                    {paymentBoard.companyName}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="12%">
                    {paymentBoard.department}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="7%">
                    {paymentBoard.managerName}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="10%">
                    {paymentBoard.managerId}
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="8%">
                    {paymentBoard.createdTime
                      ? new Date(paymentBoard.createdTime)
                          .toISOString()
                          .split("T")[0]
                      : ""}
                  </TableCellWithOverflow>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box />
        <PaginationComponent
          currentPageGroup={currentPageGroup}
          setCurrentPageGroup={setCurrentPageGroup}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPageGroup={maxPageGroup}
          pageNumbers={pageNumbers}
          pagesPerGroup={pagesPerGroup}
          navigate={navigate} // navigate 함수를 prop으로 전달
          pageUrlPattern={'/payment-board-list/:page'}
        />

        <Button
          variant="contained"
          onClick={handleResetList}
          sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
        >
          목록
        </Button>
      </Box>
    </>
  );
};

export default PaymentBoardListForm;
