// UserInfoPage.tsx
import { AppDispatch } from "@/index";
import { UserAllLoginDetailDataTemp } from "../../types/userTypes_temp";
import {
  Card,
  Typography,
  CardContent,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  fetchUserList,
  pwInitializationUser,
} from "../../redux/user/userActions";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
interface UserDetailFormProps {
  userDetailLoginDataTemp: UserAllLoginDetailDataTemp[];
}
const UserInfoFormTemp: React.FC<UserDetailFormProps> = ({
  userDetailLoginDataTemp,
}) => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  const navigate = useNavigate();
  const dispatch1: AppDispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserIdOfPw, setSelectedUserIdOfPw] = useState<string | null>(
    null
  );

  // PW 초기화 다이얼로그 열기
  const handleOpenPwInitializationDialog = (managerId: string) => {
    setSelectedUserIdOfPw(managerId);
  };

  //비번초기화
  const handlePwInitialization = () => {
    if (selectedUserIdOfPw) {
      dispatch1(pwInitializationUser(selectedUserIdOfPw))
        .then(() => {
          setSelectedUserIdOfPw(null); // 다이얼로그 닫기
        })
        .catch((error: any) => {
          console.error("Error while pwInitializationUser:", error);
        });
    }
  };

  //수정버튼 클릭
  const handleUpdateClick = (userId: string) => {
    navigate(`/user-update/${userId}`);
  };

  // 삭제 확인 다이얼로그 열기
  const handleDeleteConfirm = (userId: string) => {
    setSelectedUserId(userId);
  };

  //삭제버튼 클릭
  const handleDeleteClick = () => {
    if (selectedUserId) {
      dispatch1(deleteUser(selectedUserId))
        .then(() => {
          dispatch1(fetchUserList());
          setSelectedUserId(null); // 다이얼로그 닫기
          navigate(`/user-list`);
        })
        .catch((error: any) => {
          console.error("Error while deleting user:", error);
        });
    }
  };

  if (!userDetailLoginDataTemp || userDetailLoginDataTemp.length === 0) {
    return <div>데이터가 없습니다.</div>;
  }
  const firstUserData = userDetailLoginDataTemp[0];
  if (!firstUserData) {
    return <div>유저 정보가 없습니다.</div>;
  }
  return (
    <>
      <Card
        sx={{ minWidth: "170px", width: "100%", mb: "0px" }}
        elevation={0} // 음영 효과 제거
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-evenly"
            // flexDirection={isMobile ? "column" : "row"} // 모바일 뷰에서는 컬럼 방향으로 변경
            sx={{ width: "100%", minWith: "200px" }}
          >
            <Typography
              gutterBottom
              variant="h4"
              component="span"

              //   sx={{ fontSize: 20 }}
            >
              {userDetailLoginDataTemp[0].userId}
            </Typography>

            <Typography variant="body1" component="span">
              PC ID: {userDetailLoginDataTemp[0].ein}
            </Typography>
            {/* <Box sx={{}}>
              <Tooltip title="비밀번호 초기화">
                <IconButton
                  onClick={() => {
                    handleOpenPwInitializationDialog(
                      userDetailLoginDataTemp[0].userId
                    );
                  }}
                  size="small"
                  style={{ color: "rgb(0, 153, 0)" }} // 초록색 또는 원하는 색상
                >
                  <VpnKeyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="사원 수정">
                <IconButton
                  onClick={() => {
                    handleUpdateClick(userDetailLoginDataTemp[0].userId);
                  }}
                  size="small"
                  style={{ color: "rgb(0, 0, 197)" }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="사원 삭제">
                <IconButton
                  onClick={() => {
                    handleDeleteConfirm(userDetailLoginDataTemp[0].userId);
                  }}
                  size="small"
                  style={{ color: "rgb(197, 0, 0)" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box> */}
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={Boolean(selectedUserId)}
        onClose={() => setSelectedUserId(null)}
      >
        <DialogTitle>삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>{`'${selectedUserId}' 사원을 삭제하겠습니까?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="primary">
            네
          </Button>
          <Button
            onClick={() => setSelectedUserId(null)}
            color="secondary"
            autoFocus
          >
            아니오
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(selectedUserIdOfPw)}
        onClose={() => setSelectedUserIdOfPw(null)}
      >
        <DialogTitle>비밀번호 초기화</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`'${selectedUserIdOfPw}' 사원의 비밀번호를 초기화하시겠습니까?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwInitialization} color="primary">
            네
          </Button>
          <Button
            onClick={() => setSelectedUserIdOfPw(null)}
            color="secondary"
            autoFocus
          >
            아니오
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserInfoFormTemp;
