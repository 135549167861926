import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "@/index";
import { useSelector } from "react-redux";
import UserListFormTemp from "../../components/user_temp/userListForm_TEMP";
import { fetchUserListTemp } from "../../redux/user_temp/userActions_TEMP";
import { Box, useTheme } from "@mui/material";

const UserListPageTemp: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const userList = useSelector((state: RootState) => state.userTemp.userListTemp);
  const userListLength = userList.length;
  const theme = useTheme(); // 테마 훅 사용
  useEffect(() => {
    console.log("유저리스트 가져오는 useEffect");
    dispatch(fetchUserListTemp());
  }, [dispatch]);
  console.log("userListPage렌더링");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent:"center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            // backgroundColor: "green",
            [theme.breakpoints.down(1000)]: {
              // 화면 너비가 1000px 이하일 때
              flexDirection: "column", // 방향을 세로로 변경
            },
          }}
        >
          <div className="container mt-3 " style={{ minWidth: "300px" }}>
            <UserListFormTemp userList={userList}></UserListFormTemp>
          </div>
        </Box>
      </Box>
    </>
  );
};
export default UserListPageTemp;
