import { fetchPaymentBoardList } from "../../redux/paymentBoard/paymentBoardActions";
import PaymentBoardListForm from "../../components/paymentBoard/paymentBoardListForm";
import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "@/index";
import { useSelector } from "react-redux";

const PaymentBoardListPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const paymentBoardList = useSelector((state: RootState) => state.paymentBoard.paymentBoardList);

  useEffect(() => {
    dispatch(fetchPaymentBoardList());
  }, [dispatch]);

  console.log(" PaymentBoardListPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-0" style={{ minWidth: "500px" }}>
        <PaymentBoardListForm paymentBoardList={paymentBoardList}/>
      </div>
    </>
  );
};

export default PaymentBoardListPage;
