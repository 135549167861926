import React, { useState, FormEvent } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { RootState } from "@/index";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { registerPaymentBoard } from "../../redux/paymentBoard/paymentBoardActions";
import { PaymentBoardRegisterType } from "../../types/paymentBoardTypes";
import { useTheme } from "@mui/material/styles";
const PaymentBoardRegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(470));
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [telValid, setTelValid] = useState(true);
  const [telError, setTelError] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [registerForm, setRegisterForm] = useState<PaymentBoardRegisterType>({
    companyName: "",
    department: "",
    jobPosition: "",
    userNumber: "",
    managerName: "",
    tel: "",
    email: "",
    inquiryDetail: "",
    agreeToTerms: false,
  });
  // 이메일 유효성 검사
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const checkEmailValidity = (email: string) => {
    if (emailRegex.test(email)) {
      setEmailValid(true);
      setEmailError(null);
    } else {
      setEmailValid(false);
      setEmailError("이메일 형식에 맞지 않습니다.");
    }
  };

  // 전화번호 유효성 검사
  const telRegex = /^\d*-\d*-\d*$/; // 최소한 두 개의 '-' 가 포함되어야 함
  const checkTelValidity = (tel: string) => {
    if (telRegex.test(tel)) {
      setTelValid(true);
      setTelError(null);
    } else {
      setTelValid(false);
      setTelError("최소 2개의 대시('-')를 포함해야 합니다.");
    }
  };
  // 입력 필드 값 변경을 처리하는 함수
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;

    if (type === "file") {
      const file = e.target.files ? e.target.files[0] : null;
      // 파일 입력 처리
      if (file !== null) {
        setImageFile(file); //
      }
    } else if (type === "checkbox") {
      // 체크박스 입력 처리
      setRegisterForm({ ...registerForm, [name]: checked });
    } else {
      // 일반 입력 필드 처리
      setRegisterForm({ ...registerForm, [name]: value });

      // 추가 유효성 검사
      if (name === "email") {
        checkEmailValidity(value);
      } else if (name === "tel") {
        checkTelValidity(value);
      }
    }
  };

  // 폼 제출을 처리하는 함수
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (registerForm.agreeToTerms && emailValid && telValid) {
      console.log(registerForm);
      console.log("제출되었습니다");
      // 폼 데이터 제출 로직
      dispatch(registerPaymentBoard(registerForm, imageFile, navigate));
    } else {
      if (!registerForm.agreeToTerms) {
        alert("개인정보 이용에 동의해주세요.");
      }
      if (!emailValid || !telValid) {
        console.log("입력 데이터가 유효하지 않습니다.");
      }
    }
  };
  const handleCancel = () => {
    navigate(-1); // 이전 페이지로 돌아갑니다
  };

  return (
    <>
      {/* <Card
        className="container"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 15,
          p: 10,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            color="textSecondary"
            component="p"
            style={{
              // textAlign: "right",
              // fontSize: "25px",
              fontWeight: "bold",
              padding: "5px",
              whiteSpace: "pre",
            }}
          >
            계좌 번호 : 123-456-789 OO은행 (주)카인드소프트
          </Typography>
        </CardContent>
      </Card> */}

      <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: "320" }}>
        <Grid container spacing={2}>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="회사명"
              name="companyName"
              value={registerForm.companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="부서명"
              name="department"
              value={registerForm.department}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="담당자 성명"
              name="managerName"
              value={registerForm.managerName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="직책"
              name="jobPosition"
              value={registerForm.jobPosition}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              fullWidth
              label="사원 수"
              name="userNumber"
              value={registerForm.userNumber}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="전화번호"
              name="tel"
              value={registerForm.tel}
              onChange={handleChange}
            />
            {telError && <p style={{ color: "red" }}>{telError}</p>}
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <TextField
              required
              fullWidth
              label="이메일"
              name="email"
              value={registerForm.email}
              onChange={handleChange}
            />
            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
          </Grid>

          {/* Repeat for inquiry type */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={7}
              label="문의내용"
              name="inquiryDetail"
              value={registerForm.inquiryDetail}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField type="file" fullWidth onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="agreeToTerms"
                  checked={registerForm.agreeToTerms}
                  onChange={handleChange}
                />
              }
              label="개인정보의 수집 및 이용목적에 동의합니다."
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              maxHeight: 200,
              overflowY: "auto",
              marginTop: "10px",
              padding: "10px",
              border: "1px solid grey",
            }}
          >
            <Typography variant="body2">
              ▶ 개인정보의 수집 및 이용목적
              <br /> - 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인,
              연령제한 서비스 이용
              <br /> - 고지사항 전달, 불만처리 의사소통 경로 확보, 물품배송 시
              정확한 배송지 정보 확보
              <br /> - 신규 서비스 등 최신정보 안내 및 개인맞춤서비스 제공을
              위한 자료
              <br /> - 기타 원활한 양질의 서비스 제공 등<br /> ▶ 수집하는
              개인정보의 항목 <br />- 이름, 이메일, 주민등록번호, 주소, 연락처,
              핸드폰번호, 그 외 선택항목 <br />▶ 개인정보의 보유 및 이용기간{" "}
              <br />- 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체
              없이 파기합니다. <br />- 다만, 원활한 서비스의 상담을 위해 상담
              완료 후 내용을 3개월간 보유할 수 있으며 전자상거래에서의
              소비자보호에 관한 법률 등 타법률에 의해 보존할 필요가 있는
              경우에는 일정기간 보존합니다.
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button variant="contained" type="submit" sx={{ mr: 3 }}>
              확인
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              취소
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default PaymentBoardRegisterForm;
