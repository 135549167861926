import UserDetailFormTemp from "../../components/user_temp/userDetailForm_TEMP";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AppDispatch, RootState } from "@/index";
import { useSelector } from "react-redux";
import { fetchUserTemp } from "../../redux/user_temp/userActions_TEMP";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import UserInfoFormTemp from "../../components/user_temp/userInfoForm_TEMP";

type RouteParams = {
  [key: string]: string | undefined;
};

const UserDetailpageTemp: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<RouteParams>();
  const userId = String(id);

  const { userDetailLoginDataTemp, loading, error } = useSelector(
    (state: RootState) => state.userTemp
  );
  useEffect(() => {
    console.log("userId:", userId);
    if (userId && userId !== "") {
      dispatch(fetchUserTemp(userId));
    }
  }, [dispatch, userId]);

  console.log("UserDetailpage렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!userDetailLoginDataTemp) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <UserInfoFormTemp userDetailLoginDataTemp={userDetailLoginDataTemp}></UserInfoFormTemp>
      <UserDetailFormTemp
        userDetailLoginDataTemp={userDetailLoginDataTemp}
      ></UserDetailFormTemp>
    </div>
  );
};

export default UserDetailpageTemp;
