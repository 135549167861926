import UserDetailForm from "../../components/user/userDetailForm";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { AppDispatch, RootState } from "@/index";
import { useSelector } from "react-redux";
import { fetchUser } from "../../redux/user/userActions";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import UserInfoForm from "../../components/user/userInfoForm";

type RouteParams = {
  [key: string]: string | undefined;
};

const UserDetailpage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<RouteParams>();
  const userId = String(id);

  const { userDetailLoginData, loading, error } = useSelector(
    (state: RootState) => state.user
  );
  useEffect(() => {
    console.log("userId:", userId);
    if (userId && userId !== "") {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId]);

  console.log("UserDetailpage렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!userDetailLoginData) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <UserInfoForm userDetailLoginData={userDetailLoginData}></UserInfoForm>
      <UserDetailForm
        userDetailLoginData={userDetailLoginData}
      ></UserDetailForm>
    </div>
  );
};

export default UserDetailpage;
