import VideoPage from "../pages/introduce/VideoPage";
import FunctionPage from "../pages/introduce/FunctionPage";
import LogvisorPage from "../pages/introduce/LogvisorPage";
import ExperiencePage from "../pages/introduce/ExperiencePage";
import CompetitivePage from "../pages/introduce/CompetitivePage";

const IntroduceRoutes = [
  {
    path: "/introduce/logvisor",
    component: LogvisorPage,
  },
  {
    path: "/introduce/function",
    component: FunctionPage,
  },
  {
    path: "/introduce/competitive",
    component: CompetitivePage,
  },

  {
    path: "/introduce/video",
    component: VideoPage,
  },
  {
    path: "/introduce/experience",
    component: ExperiencePage,
  },







];

export default IntroduceRoutes;
