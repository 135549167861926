// CheckoutPage.tsx//부모
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { preparePayment, verifyPayment } from "../../redux/order/OrderActions";

declare global {
  interface Window {
    IMP: any;
  }
}

const OrderRegisterForm: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<number | "">("");

  const IMP = window.IMP;
  const IMP_KEY = process.env.REACT_APP_IMP_KEY;

  useEffect(() => {
    console.log("IMP_KEY: ", IMP_KEY);
    IMP.init(IMP_KEY);
  }, [IMP]);

  const handlePayment = () => {
    if (paymentMethod === 10) {
      console.log("handleDANAL");
      handleDanalPay();
    } else if (paymentMethod === 30) {
      console.log("handleKAKAOPAY");
      handleKakaoPay();
    }
  };
  const handleDanalPay = async () => {
    const today = new Date();
    const year = today.getFullYear(); // 년도
    const month = today.getMonth() + 1; // 월 (0부터 시작하므로 1을 더해야 함)
    const day = today.getDate(); // 일

    // 월과 일이 한 자리 수일 경우 앞에 '0'을 추가
    const monthStr = month < 10 ? `0${month}` : `${month}`;
    const dayStr = day < 10 ? `0${day}` : `${day}`;

    const dateNumber = `${year}${monthStr}${dayStr}`;

    var hours = today.getHours(); // 시
    var minutes = today.getMinutes(); // 분
    var seconds = today.getSeconds(); // 초
    var milliseconds = today.getMilliseconds();

    var makeMerchantUid =
      dateNumber +
      "_" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      ":" +
      milliseconds;

    const prepareReq = {
      merchantUid: `uid_${makeMerchantUid}`,
      amount: 100,//사전 결제검증 금액
    };

    preparePayment(prepareReq);

    IMP.request_pay(
      {
        pg: "danal_tpay", // 다날 페이 사용. 정확한 값은 아임포트 관리자 페이지에서 확인
        pay_method: "card",
        merchant_uid: "uid_" + makeMerchantUid, //주문번호
        name: "상품명",
        amount: 100, // 총 가격
        buyer_email: "kimyj1215@naver.com",
        buyer_name: "김영진",
        buyer_tel: "010-123-2222",
        buyer_addr: "서울특별시 강남구 삼성동",
        buyer_postcode: "123-456",
        // notice_url: "http://localhost:8888/payment/webhook",
        m_redirect_url: "redirect url",
      },
      function (rsp: any) {
        const { status, err_msg } = rsp;
        console.log("rsp:", rsp);
        if (rsp.success) {
          verifyPayment(rsp);
        }

        // if (err_msg) {
        // alert(err_msg);
        // }
        // if (status == "paid") {
        // const { imp_uid } = rsp; //포트원 고유 결제번호
        // verifyPayment(imp_uid);
        // alert("결제 완료");
        // }
      }
    );
  };
  const handleKakaoPay = () => {
    // 결제창 호출 코드
    IMP.request_pay(
      {
        pg: "kakaopay",
        pay_method: "card",
        merchant_uid: "merchant_" + new Date().getTime(), //주문번호
        name: "상품명",
        amount: 23000, //총 가격
        buyer_email: "구매자 이메일",
        buyer_name: "이름",
        buyer_tel: "전번",
        buyer_addr: "주소",
        buyer_postcode: "우편번호",
        m_redirect_url: "redirect url",
      },
      function (rsp: any) {
        if (rsp.success) {
          //   saveOrderInformations();

          let msg = "결제가 완료되었습니다.";
          alert(msg);
          // navigate("/order-list");
        } else {
          let msg = "결제에 실패하였습니다.";
          console.error(rsp.error_msg);
          alert(msg);
        }
      }
    );
  };

  //   const saveOrderInformations = () => {
  //     let cartItemList = [];

  //     for (let i = 0; i < actualItems.length; i++) {
  //       cartItemList.push({
  //         productId: actualItems[i].product.id,
  //         quantity: actualItems[i].quantity,
  //         size: actualItems[i].size,
  //         price: actualItems[i].product.price,
  //       });
  //     }

  //     let orderInfo = {
  //       userId: actualUser?.id,
  //       totalPrice: cartTotalPrice,
  //       cartItemList: cartItemList,
  //       name: name,
  //       postCode: postCode,
  //       address: address,
  //       detailAddress: detailAddress,
  //       phone: phone,
  //     };

  //     dispatch(saveOrder(orderInfo));
  //   };
  console.log("orderRegister 렌더링")
  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <Typography variant="h5">결제 방법</Typography>
        <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
          <InputLabel>결제 방법 선택</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value as number)}
          >
            <MenuItem value="">결제 방법 선택</MenuItem>
            <MenuItem value={10}>신용 카드</MenuItem>
            <MenuItem value={20}>계좌 이체</MenuItem>
            <MenuItem value={30}>카카오페이</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={handlePayment}
        style={{ display: "block", margin: "20px auto", width: "100px" }}
      >
        결제하기
      </Button>
    </>
  );
};

export default OrderRegisterForm;
