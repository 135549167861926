import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { signUpTest } from "../../redux/manager/managerActions";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate } from "react-router-dom";
import { FormHelperText } from "@mui/material";

export const SignUpPage_Test: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  //id 관련
  const [managerId, setManagerId] = useState("");
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [idColor, setIdColor] = useState<string>("red");
  const [idDisabled, setIdDisabled] = useState(false);
  const [isIdDuplicated, setIsIdDuplicated] = useState(false);

  //pw관련
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  //id관련 함수
  const handleManagerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 한글 및 특수문자를 제외한 영문과 숫자만 허용
    const regex = /^[A-Za-z0-9]+$/;
    if (regex.test(value) || value === "") {
      setManagerId(value);
    } else {
      setIdMessage("영문과 숫자만 사용할 수 있습니다.");
      setIdColor("red");
    }
  };

  //pw관련 함수
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const checkPasswordValidity = (password: string) => {
    if (passwordRegex.test(password)) {
      setPasswordValid(true);
      setPasswordError(null);
    } else {
      setPasswordValid(false);
      setPasswordError(
        "비밀번호는 영어, 숫자, 특수문자로 구성되어야 하며 8자 이상이어야 합니다."
      );
    }
  };

  //submit 함수
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get("managerId"), data.get("password"));
    const managerData = {
      managerId: data.get("managerId") as string,
      password: data.get("password") as string,
    };
    console.log("signup-handleSubmit메서드 작동");
    dispatch(signUpTest(managerData, navigate));
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 320 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // position: "relative",
            marginBottom: "30px",
          }}
        >
          <Typography component="h1" variant="h5">
            TEST 회원 가입
          </Typography>
        </Box>

        <Box
          component="form"
          // noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 8 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                id="managerId"
                label="ID"
                name="managerId"
                autoComplete="id"
                autoFocus
                value={managerId}
                onChange={handleManagerIdChange}
                FormHelperTextProps={{ style: { color: idColor } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="비밀번호"
                type="password"
                id="password"
                autoComplete="off"
                onChange={(e) => checkPasswordValidity(e.target.value)}
              />
              {passwordError && (
                <FormHelperText error>{passwordError}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!passwordValid}
          >
            회원가입
          </Button>

          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <Link href="/test/sign-in" variant="body2">
                TEST 로그인(post)
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <Link href="/test/sign-in-get" variant="body2">
                TEST 로그인(GET)
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    // </ThemeProvider>
  );
};
