// FindIdContainer.tsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FindIdForm from "../../components/manager/findIdForm";
import {
  fetchManagerIdByEmail,
  fetchManagerIdByTel,
} from "../../redux/manager/managerActions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { RootState } from "@/index";
import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
const FindIdPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [managerId, setManagerId] = useState<string | null>(null);

  const findIdByEmail = async (email: string) => {
    const result = await dispatch(fetchManagerIdByEmail(email));
    setManagerId(result);
    console.log("result", result);
  };

  const findIdByPhone = async (phone: string) => {
    const result = await dispatch(fetchManagerIdByTel(phone));
    setManagerId(result);
    console.log("result", result);
  };

//   const handleBack = () => {
//     navigate(-1); // 뒤로가기
//   };

  return (
    <>
      <Box>
        <FindIdForm
          findIdByEmail={findIdByEmail}
          findIdByPhone={findIdByPhone}
        />
        <Typography variant="h6" color="" align="center">
          {managerId ? <div>ID: {managerId}</div> : ""}
        </Typography>
        
      </Box>
    </>
  );
};

export default FindIdPage;
