// PaginationComponent.tsx
import React from "react";
import { Button, Box } from "@mui/material";

interface PaginationProps {
  currentPageGroup: number;
  setCurrentPageGroup: (pageGroup: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  maxPageGroup: number;
  pageNumbers: number[];
  pagesPerGroup: number;
  navigate: (path: string) => void; // navigate 함수를 prop으로 추가
  pageUrlPattern: string; // 페이지 이동 URL 패턴
}

const PaginationComponent: React.FC<PaginationProps> = ({
  currentPageGroup,
  setCurrentPageGroup,
  currentPage,
  setCurrentPage,
  maxPageGroup,
  pageNumbers,
  pagesPerGroup,
  navigate, // navigate prop 사용
  pageUrlPattern, // URL 패턴 prop 추가
}) => {
  const handlePreviousGroup = () => {
    const newPageGroup = Math.max(1, currentPageGroup - 1);
    setCurrentPageGroup(newPageGroup);
    setCurrentPage((newPageGroup - 1) * pagesPerGroup + pagesPerGroup);
  };

  const handleNextGroup = () => {
    const newPageGroup = Math.min(maxPageGroup, currentPageGroup + 1);
    setCurrentPageGroup(newPageGroup);
    setCurrentPage((newPageGroup - 1) * pagesPerGroup + 1);
  };

  // 페이지 번호 클릭 핸들러 수정
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (!pageUrlPattern) {
      console.error("URL 패턴이 제공되지 않았습니다.");
      return; // 또는 기본 URL 패턴을 설정
    }
    // URL 패턴을 사용하여 페이지 이동 경로 생성
    const newPath = pageUrlPattern.replace(":page", pageNumber.toString());
    navigate?.(newPath); // 옵셔널 체이닝 사용
  };

  return (
    <Box display="flex" justifyContent="center">
      <Button
        onClick={handlePreviousGroup}
        disabled={currentPageGroup === 1}
        sx={{ mx: 5 }}
      >
        {"←이전"}
      </Button>
      {pageNumbers.map((pageNumber) => (
        <Button
          key={pageNumber}
          onClick={() => handlePageClick(pageNumber)} // 수정된 핸들러 사용
          variant={currentPage === pageNumber ? "contained" : "outlined"}
          sx={{ mx: 0.5, minWidth: "0px", width: "40px" }}
        >
          {pageNumber}
        </Button>
      ))}
      <Button
        onClick={handleNextGroup}
        disabled={currentPageGroup === maxPageGroup}
        sx={{ mx: 5 }}
      >
        {"다음→"}
      </Button>
    </Box>
  );
};

export default PaginationComponent;
