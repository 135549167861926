import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // 이 옵션이 스크롤을 즉시 이동시킵니다.
    });
  }, [pathname]);

  return null; // UI를 렌더링하지 않는 컴포넌트
};

export default ScrollToTop;
