import OrderRegisterForm from "../../components/order/OrderRegisterForm";
import React from "react";

const OrderRegisterPage: React.FC = () => {

  return (
    <div className="container mt-5">
        <OrderRegisterForm></OrderRegisterForm>
    </div>
  );
};

export default OrderRegisterPage;
