import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { PaymentBoard } from "../../types/paymentBoardTypes";

interface PaymentBoardDetailFormProps {
  paymentBoard: PaymentBoard;
}

const PaymentBoardDetailForm: React.FC<PaymentBoardDetailFormProps> = ({
  paymentBoard,
}) => {
  const fileDownloadUrl = `http://logvisor.com/images/payment-board/${paymentBoard.fileUrl}`;
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  return (
    <Paper sx={{ padding: "20px", margin: "20px", minWidth: 320, minHeight: "100px"   }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: "inline", fontWeight: "bold" ,marginLeft:5}}
        >
          {paymentBoard.companyName} - {paymentBoard.department}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", fontWeight: "light",  }}
        >
          {paymentBoard.createdTime ? formatDate(paymentBoard.createdTime) : ""}
        </Typography>
        {/* <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", marginX: 5 }}
        >
          글번호.{paymentBoard.paymentBoardId}
        </Typography> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          mt: 2,
          flexDirection: { xs: "column", lg: "row" }, // 600px 이하에서는 칼럼 방향

        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
     
          }}
        >
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            관리자ID: {paymentBoard.managerId}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            관리자명: {paymentBoard.managerName}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            직책: {paymentBoard.jobPosition}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            사원 수: {paymentBoard.userNumber}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
  
          }}
        >
          <Typography variant="body1" sx={{ display: "inline", mx:2  }}>
            전화번호: {paymentBoard.tel}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2  }}>
            이메일: {paymentBoard.email}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginY: 10 }}>
        {/* <Typography variant="h5" sx={{marginY:5 , fontWeight:"bold" }}>문의 상세 내용</Typography> */}
        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap"  , wordWrap: "break-word" }}>
          {paymentBoard.inquiryDetail}
        </Typography>
      </Box>
      {paymentBoard.fileUrl && (
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ marginY: 5, fontWeight: "bold" }}>
            첨부 파일
          </Typography>
          <a
            href={fileDownloadUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            파일 다운로드
          </a>
        </Box>
      )}
    </Paper>
  );
};

export default PaymentBoardDetailForm;
