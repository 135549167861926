import { Box, Button, Container, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExperienceButton from "../../components/ExperienceButton";
const ExperiencePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            textAlign="center"
            marginTop={5}
            marginBottom={3}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                color: "black",
                // fontStyle: "italic",
                lineHeight: "1.5",
                marginBottom: "50px",
                "@media (max-width: 800px)": {
                  fontSize: "25px", //
                },
              }}
            >
              PC 로그인 보안솔루션{" "}
              <span style={{ color: " #0057af" }}>LogVisor</span>
              <br></br> 직접 체험하고 확인하세요
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              sx={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                color: "black",
                // fontStyle: "italic",
                lineHeight: "1.5",
                marginBottom: "30px",
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              LogVisor 체험하기란?
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                color: "",
                // fontStyle: "italic",
                lineHeight: "1.5",
              }}
            >
              LogVisor{" "}
              <Link
                href="/sign-up" // 이동하고자 하는 경로
                sx={{
                  color: " #0057af",
                  textDecoration: "none", // 기본 밑줄 제거
                  "&:hover": {
                    textDecoration: "underline", // 호버 시 밑줄
                    cursor: "pointer", // 마우스 오버 시 커서 변경
                  },
                }}
              >
                관리자페이지에 접속
              </Link>
              하여 도입 전 LogVisor의 다양한 기능과 편리함을 체험하실 수
              있습니다.
              <br></br> 전체 기능 사용을 원하시는 분은{" "}
              <Link
                href="/payment-board-register" // 이동하고자 하는 경로
                sx={{
                  color: " #0057af",
                  textDecoration: "none", // 기본 밑줄 제거
                  "&:hover": {
                    textDecoration: "underline", // 호버 시 밑줄
                    cursor: "pointer", // 마우스 오버 시 커서 변경
                  },
                }}
              >
                도입문의
              </Link>
              를 클릭하여 신청해주세요
            </Typography>
           <ExperienceButton></ExperienceButton>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ExperiencePage;
