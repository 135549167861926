import mainRequest from "../../api/mainRequest";

export const preparePayment = async (prepareReq: any) => {
  const response = await mainRequest.post(`/payment/prepare`, prepareReq);
  console.log("사전결제 예정금액 등록 완료");
};

export const verifyPayment = async (rsp: any) => {
  try {
    const response = await mainRequest.post(`/payment/validate`, {
      impUid: rsp.imp_uid, // 결제 고유번호
      merchantUid: rsp.merchant_uid, // 주문번호
      amount: rsp.paid_amount,
    });

    // 가맹점 서버 결제 API 성공시 로직
    console.log(response.data);

    if (rsp.paid_amount === response.data.response.amount) {
      alert("결제 성공");
    } else {
      alert("결제 실패");
    }
  } catch (error) {
    console.error("결제 검증 에러:", error);
    alert("결제 검증 중 오류가 발생했습니다.");
  }
};

// export const verifyPayment = async (rsp: any) => {
//   const { status, err_msg } = rsp;
//   console.log("rsp:", rsp);

//   try {
//     const response = await mainRequest.post(
//       `/payment/verify/${rsp.imp_uid}`
//     );
//     console.log("response.data.response: ", response.data.response);

//     if (rsp.paid_amount === response.data.response.amount) {
//       alert("결제 성공");
//     } else {
//       alert("결제 실패");
//     }
//   } catch (error) {
//     console.error("결제 검증 에러:", error);
//     alert("결제 검증 중 오류가 발생했습니다.");
//   }
// };

// export const verifyPayment =
//   (
//     imp_uid: string
//   ): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
//   async (dispatch, getState) => {
//     try {
//       const response = await mainRequest.post(`/order/verify`, imp_uid);
//       console.log("통신후responsFdata", response.data);

//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(); // 실패 시 Promise.reject 반환
//     }
//   };
