import React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  fetchManager,
  fetchMyManagerInfo,
  updateManager,
} from "../../redux/manager/managerActions";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Manager } from "@/types/managerTypes";
import Loading from "../../components/Loading";

//하단 장식
const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.kind-soft.com/">
        KINDSOFT Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
type RouteParams = {
  [key: string]: string | undefined;
};

export const ManagerUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const { id } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState<Manager | null>(null);

  useEffect(() => {
    if (id) {
      // admin page에서 manager정보수정 아이콘을 클릭했을경우
      console.log("useEffect1- fetchManager(id)호출 id:", id);
      dispatch(fetchManager(id))
        .then((data) => {
          console.log("setIsLoading작동"); //상태 업데이트가 화면에 반영되기 전에 실행됩니다.
          setIsLoading(false); //비동기적 처리
          setFetchedData(data); // 비동기적 처리 가져온 데이터를 상태에 저장
          console.log(" useEffect1 - 전달받은 data: ", data);
        })
        .catch((error: any) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      // navbar에서 내정보를 클릭했을경우
      console.log("id가없습니다.");
      dispatch(fetchMyManagerInfo())
        .then((data) => {
          console.log("setIsLoading작동"); //상태 업데이트가 화면에 반영되기 전에 실행됩니다.
          setIsLoading(false); //비동기적 처리
          setFetchedData(data); // 비동기적 처리 가져온 데이터를 상태에 저장
          console.log(" useEffect1 - 전달받은 data: ", data);
        })
        .catch((error: any) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [dispatch, id]);

  //useSelect를 사용하면 또 리랜더링이되버림.
  // const fetchedManager = useSelector(
  //   (state: RootState) => state.manager.manager
  // );

  console.log("manager update page렌더링");
  // console.log("일반페이지-fetchedManager:", fetchedManager);
  useEffect(() => {
    console.log("useEffect2들어옴");
    if (fetchedData) {
      console.log("useEffect2 - fetchedData:", fetchedData);
      setManagerId(fetchedData.managerId);
      setCompany(fetchedData.company);
      setCrn(fetchedData.crn);
      setEmail(fetchedData.email);
      setTel(fetchedData.tel);
    }
  }, [fetchedData]); // useEffect는 컴포넌트가 최초로 렌더링될 때와 fetchedManager 상태가 변경될 때마다 실행됩니다.

  //id 관련
  const [managerId, setManagerId] = useState("");

  //pw관련
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmValid, setConfirmValid] = useState(true);
  const [confirmError, setConfirmError] = useState<string | null>(null);

  //컴퍼니 관련

  const [company, setCompany] = useState("");
  const [companyValid, setCompanyValid] = useState(true);
  const [companyError, setCompanyError] = useState<string | null>(null);

  //사업자등록번호(CRN) 관련

  const [crn, setCrn] = useState("");
  const [crnValid, setCrnValid] = useState(true);
  const [crnError, setCrnError] = useState<string | null>(null);

  //이메일 관련

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState<string | null>(null);

  //전화번호 관련
  const [tel, setTel] = useState("");
  const [telValid, setTelValid] = useState(true);
  const [telError, setTelError] = useState<string | null>(null);

  //pw관련 함수
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const checkPasswordValidity = (password: string) => {
    setPassword(password);
    if (password.length === 0) {
      setPasswordValid(true);
      setConfirmValid(true);
    } else if (passwordRegex.test(password)) {
      setPasswordValid(true);
      setConfirmValid(false);
      setPasswordError(null);
    } else {
      setPasswordValid(false);
      setPasswordError(
        "비밀번호는 영어, 숫자, 특수문자로 구성되어야 하며 8자 이상이어야 합니다."
      );
    }
  };
  //pw 일치확인 로직
  const checkConfirmValidity = (password: string, confirm: string) => {
    if (password === confirm) {
      setConfirmValid(true);
      setConfirmError(null);
    } else {
      setConfirmValid(false);
      setConfirmError("비밀번호가 일치하지 않습니다.");
    }
  };

  //컴퍼니 관련 함수
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCompany(value);
    setCompanyValid(value.trim() !== ""); // 컴퍼니명이 비어있지 않은 경우에만 true
  };

  //사업자등록번호(crn) 관련 함수

  const crnRegex = /^\d{3}-\d{2}-\d{5}$/;

  const handleCrnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrn(e.target.value);
    checkCrnValidity(e.target.value);
  };
  const checkCrnValidity = (crn: string) => {
    if (crnRegex.test(crn)) {
      setCrnValid(true);
      setCrnError(null);
    } else {
      setCrnValid(false);
      setCrnError("사업자 등록 번호는 xxx-xx-xxxxx 형식으로 작성해 주세요");
    }
  };

  //e메일 관련 함수
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    checkEmailValidity(e.target.value);
  };
  const checkEmailValidity = (email: string) => {
    if (emailRegex.test(email)) {
      setEmailValid(true);
      setEmailError(null);
    } else {
      setEmailValid(false);
      setEmailError("이메일 형식에 맞지 않습니다.");
    }
  };

  //전화번호 관련 함수
  const telRegex = /^\d*-\d*-\d*$/; // 최소한 두 개의 '-' 가 포함되어야 함
  const handleTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTel(value);

    if (telRegex.test(value)) {
      setTelValid(true);
      setTelError(null);
    } else {
      setTelValid(false);
      setTelError(
        "최소 2개의 대시('-')를 포함해야 합니다. 지역번호를 입력하거나 휴대폰 번호를 입력해주세요"
      );
    }
  };

  // 수정된 필드를 추적하는 상태
  const [isModified, setIsModified] = useState(false);

  // 각 입력 필드의 onChange 핸들러에서 이 상태를 업데이트
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsModified(true); // 입력이 변경되면 수정됨으로 설정
  };

  // '수정' 버튼의 활성화 여부 결정
  const isSubmitDisabled = !(
    isModified &&
    emailValid &&
    telValid &&
    crnValid &&
    companyValid &&
    (password.length === 0 || (passwordValid && confirmValid))
  );

  //submit 함수
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(
      data.get("managerId"),
      data.get("password"),
      data.get("company"),
      data.get("crn"),
      data.get("email"),
      data.get("tel")
    );
    const managerData: any = {
      managerId: data.get("managerId") as string,
    };
    if (fetchedData) {
      if (company !== fetchedData.company) {
        managerData.company = company;
      }
      if (crn !== fetchedData.crn) {
        managerData.crn = crn;
      }
      if (email !== fetchedData.email) {
        managerData.email = email;
      }
      if (tel !== fetchedData.tel) {
        managerData.tel = tel;
      }
      if (password && passwordValid && confirmValid) {
        managerData.password = password;
      }
    }
    console.log("manager-update-handleSubmit메서드 작동");
    dispatch(updateManager(managerData, navigate));
  };

  if (isLoading) {
    console.log("Loading...중");
    return  <Loading/>; // 또는 로딩 스피너 등을 표시
  }

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 320 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginBottom: "30px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
          >
            관리자 정보 수정
          </Typography>
          <Box
            component="img"
            sx={{
              position: "absolute",
              left: "100%",
              marginLeft: "30px",
              width: 100, // 너비 설정
              // height: 65, // 높이 설정
              // objectFit: "cover", // 이미지가 박스에 맞게 조정
            }}
            alt={""}
            // src="/images/JJokomi-removebg1.png"
            // src="/images/ci2-removebg-preview.png"
            src="/images/JJokomi-removebg1.png"
            // src="/images/kiny1-removebg-preview.png"
          />
        </Box>
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          관리자 정보 수정
        </Typography> */}
        <Box
          component="form"
          // noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 8 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="managerId"
                label="ID"
                name="managerId"
                autoComplete="id"
                value={managerId}
                inputProps={{ readOnly: true }} //  필드 활성화/비활성화
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="비밀번호 (미입력시 수정 안함)"
                type="password"
                id="password"
                value={password}
                autoComplete="off"
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  checkPasswordValidity(inputEvent.target.value);
                  handleInputChange(inputEvent);
                }}
              />
              {passwordError && (
                <FormHelperText error>{passwordError}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="confirmPassword"
                label="비밀번호 확인"
                type="password"
                id="confirmPassword"
                autoComplete="off"
                onChange={(e) => {
                  checkConfirmValidity(
                    (document.getElementById("password") as HTMLInputElement)
                      .value,
                    e.target.value
                  );
                }}
              />
              {confirmError && (
                <FormHelperText error>{confirmError}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="company"
                label="회사 또는 그룹 명"
                name="company"
                value={company}
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleCompanyChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!companyError} // 에러가 있을 때 필드에 에러 표시
                helperText={companyError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="crn"
                label="사업자 등록 번호(-포함)"
                name="crn"
                value={crn}
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleCrnChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!crnError} // 에러가 있을 때 필드에 에러 표시
                helperText={crnError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="이메일"
                name="email"
                value={email}
                autoComplete="email"
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleEmailChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!emailError} // 이메일 에러가 있을 때, 필드에 에러 표시
                helperText={emailError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="tel"
                label="전화번호(-포함)"
                name="tel"
                value={tel}
                autoComplete="tel"
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleTelChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!telError} // 에러가 있을 때 필드에 에러 표시
                helperText={telError} // 에러 메시지 출력
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitDisabled}
          >
            수정
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
    // </ThemeProvider>
  );
};
