import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { TechnicalBoardList } from "../../types/technicalBoardTypes";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../Pagination";
import SearchIcon from "@mui/icons-material/Search";
interface TechnicalBoardListFormProps {
  technicalBoardList: TechnicalBoardList[];
}
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp, // prop으로 받은 widthProp로 너비 고정
      // widthProp: widthProp, // prop으로 받은 widthProp 사용
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      // border: "1px solid lightgrey",
      // border:0,
      textAlign: "center",
      fontFamily: isHeader ? "'Nanum Gothic'" : undefined, // isHeader가 true일 때만 글꼴 적용
      fontSize: isHeader ? "16px" : undefined, // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader ? "bold" : undefined, // isHeader가 true일 때만 글자 굵기 적용
    }}
  >
    {children}
  </TableCell>
);

const TechnicalBoardListForm: React.FC<TechnicalBoardListFormProps> = ({
  technicalBoardList,
}) => {
  const { page } = useParams<{ page: string }>(); // URL에서 페이지 번호 추출
  const navigate = useNavigate();
  const [filteredTechnicalBoardList, setFilteredTechnicalBoardList] =
    useState<TechnicalBoardList[]>(technicalBoardList);

  // URL에서 가져온 페이지 번호를 정수로 변환하고, 유효하지 않으면 1로 설정
  const initialPage = parseInt(page || '1', 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const pagesPerGroup = 10; //
  const itemsPerPage = 12;

  let numberOfPages = Math.ceil(technicalBoardList.length / itemsPerPage);
  let maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);

  useEffect(() => {
    // URL의 페이지 번호가 변경될 때마다 현재 페이지 상태 업데이트
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    // userList가 업데이트될 때마다 filteredUserList도 업데이트
    setFilteredTechnicalBoardList(technicalBoardList);
  }, [technicalBoardList]);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const filterTechnicalBoardList = (searchValue: any) => {
    const filtered = technicalBoardList.filter(
      (technicalBoard: TechnicalBoardList) => {
        if (
          searchValue &&
          !(
            technicalBoard.companyName.includes(searchValue) ||
            technicalBoard.managerName.includes(searchValue) ||
            technicalBoard.inquiryDetail.includes(searchValue)
          )
        )
          return false;
        return true;
      }
    );
    setFilteredTechnicalBoardList(filtered);
  };

  const executeSearch = () => {
    setCurrentPage(1);
    setCurrentPageGroup(1);
    const searchValue = searchInputRef.current?.value;
    filterTechnicalBoardList(searchValue);
  };

  // 엔터 키를 눌렀을 때 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      executeSearch();
    }
  };
  const handleSearchClick = () => {
    executeSearch();
  };
  const handleResetList = () => {
    setFilteredTechnicalBoardList(technicalBoardList); // 기술 문의 목록을 초기 상태로 재설정
    if (searchInputRef.current) {
      searchInputRef.current.value = ""; // 검색 입력 필드를 비웁니다.
    }
    setCurrentPage(1);
    setCurrentPageGroup(1);
  };

  const sortedTechnicalBoardList = filteredTechnicalBoardList;

  numberOfPages = Math.ceil(sortedTechnicalBoardList.length / itemsPerPage);
  maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);
  const displayedTechnicalBoardList = sortedTechnicalBoardList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log("technicalBoardListForm렌더링");
  const pageNumbers = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1
  ).slice(
    (currentPageGroup - 1) * pagesPerGroup, // 은 현재 페이지 그룹의 시작 페이지 번호를 계산
    currentPageGroup * pagesPerGroup // 현재 페이지 그룹의 마지막 페이지 번호
    //이는 다음 페이지 그룹의 시작 번호와 동일하므로, 실제로는 이 번호보다 하나 작은 페이지까지가 현재 페이지 그룹에 포함됩니다.
  );

  //로우 클릭
  const handleRowClick = (technicalBoardId: string) => {
    navigate(`/technical-board-detail/${technicalBoardId}`); // 상세 페이지로 이동
  };

  return (
    <>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        // p={0}
        // mx={0}
        // my={0}
        sx={{}}
      >
        <Box>
          <span
            className="h3"
            style={{
              fontWeight: "bold",
              fontFamily: "'Nanum Gothic'",
              color: "black",
              // fontSize: "17px",
            }}
          >
            기술 문의
          </span>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search name or company"
            variant="outlined"
            // value={searchName}
            // onChange={handleSearchNameChange}
            inputRef={searchInputRef}
            onKeyDown={handleKeyDown}
            sx={{ minWidth: 200, width: 350 }}
          />
          <Button
            variant="outlined"
            onClick={handleSearchClick} // 검색 버튼 클릭
            sx={{ minWidth: "fit-content", height: 55 }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          marginBottom: "30px",
          marginTop: "30px",
          // textOverflow: "ellipsis",
          // overflow: "hidden",
          // whiteSpace: "nowrap",
        }}
      >
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead sx={{ backgroundColor: "grey.200" }}>
            <TableRow>
              <TableCellWithOverflow widthProp="6%" isHeader>
                글번호
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="40%" isHeader>
                글 내용
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="12%" isHeader>
                회사명
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="12%" isHeader>
                부서
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="7%" isHeader>
                관리자명
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="10%" isHeader>
                관리자ID
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="8%" isHeader>
                작성일
              </TableCellWithOverflow>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedTechnicalBoardList.map((technicalBoard, index) => (
              <TableRow
                key={technicalBoard.technicalBoardId}
                hover // 마우스 오버 스타일
                onClick={() => handleRowClick(technicalBoard.technicalBoardId)}
                style={{ cursor: "pointer" }} // 마우스 포인터 변경
              >
                <TableCellWithOverflow widthProp="6%">
                  {technicalBoard.technicalBoardId}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="40%">
                  {technicalBoard.inquiryDetail}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%">
                  {technicalBoard.companyName}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%">
                  {technicalBoard.department}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="7%">
                  {technicalBoard.managerName}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="10%">
                  {technicalBoard.managerId}
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="8%">
                  {technicalBoard.createdTime
                    ? new Date(technicalBoard.createdTime)
                        .toISOString()
                        .split("T")[0]
                    : ""}
                </TableCellWithOverflow>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box />
        <PaginationComponent
          currentPageGroup={currentPageGroup}
          setCurrentPageGroup={setCurrentPageGroup}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPageGroup={maxPageGroup}
          pageNumbers={pageNumbers}
          pagesPerGroup={pagesPerGroup}
          navigate={navigate} // navigate 함수를 prop으로 전달
          pageUrlPattern={'/technical-board-list/:page'}
        />

        <Button
          variant="contained"
          onClick={handleResetList}
          sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
        >
          목록
        </Button>
      </Box>
    </>
  );
};

export default TechnicalBoardListForm;
