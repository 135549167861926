// ProductCard.tsx
import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserLastLoginData } from "../../types/userTypes";

interface UserCardProps {
  user: UserLastLoginData;
}

const UserCardForm: React.FC<UserCardProps> = ({ user }) => {
  const navigate = useNavigate();

  const handleCardClick = (userId: string) => {
    // dispatch(fetchUser(userId));
    navigate(`/user-detail/${userId}/1`);
  };

  return (
    <Card
      sx={{
        minWidth: "180px",
        maxHeight: "140px",
        width: "250px",
        margin: 2,
        borderRadius: 4,
      }}
      key={user.userId}
    >
      <CardActionArea onClick={() => handleCardClick(user.userId)}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="span"
            sx={{ fontSize: 20 }}
          >
            {user.userId}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            component="span"
          >
            {" "}{user.companyName}
          </Typography>

          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body2" component="span">
              User: {user.ein}
            </Typography>
            <Typography variant="body2" component="span">
              Last Login: {user.loginDate} {user.loginTime}
            </Typography>

            <Typography variant="body2" component="span">
              Login Type: {user.loginType}
            </Typography>
            <Typography variant="body2" component="span">
              IP address: {user.pcIp}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default UserCardForm;
