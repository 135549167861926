import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {  RootState } from "@/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import TechnicalBoardDetailForm from "../../components/technicalBoard/technicalBoardListDetailForm";
import { fetchTechnicalBoard } from "../../redux/technicalBoard/technicalBoardActions";

type RouteParams = {
  [key: string]: string | undefined;
};

const TechnicalBoardDetailPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<RouteParams>();
  const technicalBoardId = String(id);

  const { technicalBoard, loading, error } = useSelector(
    (state: RootState) => state.technicalBoard
  );
  useEffect(() => {
    console.log("technicalBoardId:", technicalBoardId);
    if (technicalBoardId && technicalBoardId !== "") {
      dispatch(fetchTechnicalBoard(technicalBoardId));
    }
  }, [dispatch, technicalBoardId]);

  console.log("TechnicalBoardDetailpage 렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!technicalBoard) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <TechnicalBoardDetailForm
        technicalBoard={technicalBoard}
      ></TechnicalBoardDetailForm>
    </div>
  );
};

export default TechnicalBoardDetailPage;
