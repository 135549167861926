import ManagerListPage from "../pages/admin/managerListPage";
import SignInPage from "../pages/manager/signInPage";
import {SignUpPage} from "../pages/manager/signUpPage";
import { ManagerUpdatePage } from "../pages/manager/managerUpdatePage";
import FindIdPage from "../pages/manager/findIdPage";
import { SignUpPage_Test } from "../pages/login_test/signUpPage_Test";
import SignInPage_Test from "../pages/login_test/signInPage_Test";
import LoginStatusPage_text from "../pages/login_test/loginStatusPage_text";
import SignInPage_Test_GET from "../pages/login_test/signInPage_Test_GET";


const managerRoutes = [
  {
    path: "/sign-up",
    component: SignUpPage,
  },
  {
    path: "/sign-in",
    component: SignInPage,
  },
  {
    path: "/manager-list/:page",
    component:  ManagerListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/admin-list",
    component:  ManagerListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/manager-update",
    component:  ManagerUpdatePage,
    allowedRoles: ["0","1"],
  },
  {
    path: "/manager-update/:id",
    component:  ManagerUpdatePage,
    allowedRoles: ["1"],
  },
  {
    path: "/find-manager-id",
    component:  FindIdPage,
  },


  {
    path: "/test/sign-up",
    component: SignUpPage_Test,
  },
  {
    path: "/test/sign-in",
    component: SignInPage_Test,
  },
  {
    path: "/test/sign-in-get",
    component: SignInPage_Test_GET,
  },
  {
    path: "/test/login-status",
    component: LoginStatusPage_text,
  },



];

export default managerRoutes;
