import TechnicalBoardRegisterForm from "../../components/technicalBoard/technicalBoardRegisterForm";
import React from "react";

const TechnicalBoardRegisterPage: React.FC = () => {
  console.log(" TechnicalBoardRegisterPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-5">
        <TechnicalBoardRegisterForm/>
      </div>
    </>
  );
};

export default TechnicalBoardRegisterPage;
