// User shape 정의

export interface UserTemp {
  id?: number;
  userId:string;
  ein:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface UserUpdate {
  id?: number;
  userId:string;
  managerId?:string;
  ein?:string;
  department?:string;
  email?: string;
  tel?:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserLastLoginDataTemp{
  id?: number;
  userId:string;
  ein:string;
  companyName?:string;
  loginDate:string;
  loginTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserAllLoginDetailDataTemp{
  id?: number;
  userId:string;
  ein:string;
  loginDate:string;
  loginTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface Company{
  companyName:string;
}

export interface ErrorResponse {
  message: string;
}

export interface UserState {
  loading: boolean;
  userTemp: UserTemp | null;
  userListTemp: UserLastLoginDataTemp[];
  userDetailLoginDataTemp: UserAllLoginDetailDataTemp[];
  error: string | null;
}

export const FETCH_USERLIST_REQUEST_TEMP = "FETCH_USERLIST_REQUEST_TEMP";
export const FETCH_USERLIST_SUCCESS_TEMP = "FETCH_USERLIST_SUCCESS_TEMP";
export const FETCH_USERLIST_FAILURE_TEMP = "FETCH_USERLIST_FAILURE_TEMP";

export const FETCH_USER_REQUEST_TEMP = "FETCH_USER_REQUEST_TEMP";
export const FETCH_USER_SUCCESS_TEMP = "FETCH_USER_SUCCESS_TEMP";
export const FETCH_USER_FAILURE_TEMP = "FETCH_USER_FAILURE_TEMP";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const PWINIT_USER = "PWINIT_USER";
export const PWINIT_USER_SUCCESS = "PWINIT_USER_SUCCESS";
export const PWINIT_USER_FAILURE = "PWINIT_USER_FAILURE";


type FetchUserListRequestActionTemp = {
  type: typeof FETCH_USERLIST_REQUEST_TEMP;
};

type FetchUserListSuccessActionTemp = {
  type: typeof FETCH_USERLIST_SUCCESS_TEMP;
  payload: UserLastLoginDataTemp[];
};

type FetchUserListFailureActionTemp = {
  type: typeof FETCH_USERLIST_FAILURE_TEMP;
  payload: string;
};

interface FetchUserRequestActionTemp {
  type: typeof FETCH_USER_REQUEST_TEMP;
}

interface FetchUserSuccessActionTemp {
  type: typeof FETCH_USER_SUCCESS_TEMP;
  payload: UserAllLoginDetailDataTemp[];
}

interface FetchUserFailureActionTemp {
  type: typeof FETCH_USER_FAILURE_TEMP;
  error: string;
}


interface DeleteUserAction {
  type: typeof DELETE_USER;
}
interface DeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

interface DeleteUserFailtureAction {
  type: typeof DELETE_USER_FAILURE;
  error: string;
}

interface PwInitUserAction {
  type: typeof PWINIT_USER;
}
interface PwInitUserSuccessAction {
  type: typeof PWINIT_USER_SUCCESS;
  payload: string;
}

interface PwInitUserFailtureAction {
  type: typeof PWINIT_USER_FAILURE;
  error: string;
}

export type UserActionTypes =
  | FetchUserListRequestActionTemp
  | FetchUserListSuccessActionTemp
  | FetchUserListFailureActionTemp
  | FetchUserRequestActionTemp
  | FetchUserSuccessActionTemp
  | FetchUserFailureActionTemp
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserFailtureAction
  | PwInitUserAction
  | PwInitUserSuccessAction
  | PwInitUserFailtureAction;
