export interface TechnicalBoardRegisterType {
  companyName: string;
  department: string;
  jobPosition: string;
  managerName: string;
  tel: string;
  email: string;
  inquiryDetail: string;
  agreeToTerms: boolean;
}

export interface TechnicalBoardList {
  technicalBoardId: string;
  managerId: string;
  companyName: string;
  department: string;
  managerName: string;
  inquiryDetail: string;
  createdTime: string;
}

export interface TechnicalBoard {
  technicalBoardId: string;
  managerId: string;
  companyName: string;
  department: string;
  jobPosition: string;
  managerName: string;
  tel: string;
  email: string;
  inquiryDetail: string;
  fileUrl: string;
  createdTime: string;
}

export interface TechnicalBoardState {
  loading: boolean;
  technicalBoard:  TechnicalBoard | null;
  technicalBoardList: TechnicalBoardList[];
  error: string | null;
}
export const FETCH_TECHNICALBOARDLIST_REQUEST =
  "FETCH_TECHNICALBOARDLIST_REQUEST";
export const FETCH_TECHNICALBOARDLIST_SUCCESS =
  "FETCH_TECHNICALBOARDLIST_SUCCESS";
export const FETCH_TECHNICALBOARDLIST_FAILURE =
  "FETCH_TECHNICALBOARDLIST_FAILURE";
export const FETCH_TECHNICALBOARD_REQUEST = "FETCH_TECHNICALBOARD_REQUEST";
export const FETCH_TECHNICALBOARD_SUCCESS = "FETCH_TECHNICALBOARD_SUCCESS";
export const FETCH_TECHNICALBOARD_FAILURE = "FETCH_TECHNICALBOARD_FAILURE";

type FetchTechnicalBoardListRequestAction = {
  type: typeof FETCH_TECHNICALBOARDLIST_REQUEST;
};

type FetchTechnicalBoardListSuccessAction = {
  type: typeof FETCH_TECHNICALBOARDLIST_SUCCESS;
  payload: TechnicalBoardList[];
};

type FetchTechnicalBoardListFailureAction = {
  type: typeof FETCH_TECHNICALBOARDLIST_FAILURE;
  payload: string;
};

interface FetchTechnicalBoardRequestAction {
  type: typeof FETCH_TECHNICALBOARD_REQUEST;
}

interface FetchTechnicalBoardSuccessAction {
  type: typeof FETCH_TECHNICALBOARD_SUCCESS;
  payload: TechnicalBoard;
}

interface FetchTechnicalBoardFailureAction {
  type: typeof FETCH_TECHNICALBOARD_FAILURE;
  error: string;
}

export type TechnicalBoardActionTypes =
  | FetchTechnicalBoardListRequestAction
  | FetchTechnicalBoardListSuccessAction
  | FetchTechnicalBoardListFailureAction
  | FetchTechnicalBoardRequestAction
  | FetchTechnicalBoardSuccessAction
  | FetchTechnicalBoardFailureAction;
