import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Button,
} from "@mui/material";

import { Manager } from "../../types/managerTypes";
import { grey } from "@mui/material/colors";
import ManagerCardForm from "./managerCardForm";
import PaginationComponent from "../Pagination";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@/index";
import { useDispatch } from "react-redux";
import { Company } from "../../types/userTypes";
import { getUniqueCompanyNamesOfManager } from "../../redux/manager/managerActions";
import SearchIcon from "@mui/icons-material/Search";
interface ManagerListFormProps {
  managerList: Manager[];
}
const ManagerListForm: React.FC<ManagerListFormProps> = ({ managerList }) => {
  const navigate = useNavigate();
  const { page } = useParams<{ page: string }>(); // URL에서 페이지 번호 추출
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [filteredManagerList, setFilteredManagerList] =
    useState<Manager[]>(managerList);

  // URL에서 가져온 페이지 번호를 정수로 변환하고, 유효하지 않으면 1로 설정
  const initialPage = parseInt(page || "1", 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPageGroup, setCurrentPageGroup] = useState(1);

  const [prevSize, setPrevSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const pagesPerGroup = 10;
  const [itemsPerPage, setItemsPerPage] = useState(12); // 초기 값은 12로 설정
  const [company, setCompany] = useState<string>("");
  const [companyList, setCompanyList] = useState<Company[]>([]);
  let numberOfPages = Math.ceil(managerList.length / itemsPerPage);
  let maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);


  useEffect(() => {
    // URL의 페이지 번호가 변경될 때마다 현재 페이지 상태 업데이트
    setCurrentPage(initialPage);
  }, [initialPage]);
  useEffect(() => {
    const height = window.innerHeight;
    if (height < 1200) {
      setItemsPerPage(12);
    } else {
      setItemsPerPage(24);
    }
    console.log("창크기가 변경됩니다.");
    window.addEventListener("resize", updateItemsPerPage); // 창 크기 변경 시 업데이트

    return () => {
      //다른 페이지로 이동할떄 작동함
      console.log("창크기 리스너가 제거됩니다.");
      window.removeEventListener("resize", updateItemsPerPage); // 컴포넌트 언마운트 시 이벤트 리스너 제거
    };
  }, [prevSize]);

  useEffect(() => {
    console.log("회사이름들 가져오는 useEffect");
    dispatch(getUniqueCompanyNamesOfManager())
      .then((data) => {
        setCompanyList(data); // 비동기적 처리 가져온 데이터를 상태에 저장
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [dispatch]);

  useEffect(() => {
    setFilteredManagerList(managerList);
  }, [managerList]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filterManagerList = (searchValue: any, companyValue: string) => {
    const filtered = managerList.filter((manager: Manager) => {
      if (companyValue && manager.company !== companyValue) return false;
      if (
        searchValue &&
        !(
          manager.managerId.includes(searchValue) ||
          manager.company.includes(searchValue)
        )
      )
        return false;
      return true;
    });
    setFilteredManagerList(filtered);
  };
  const updateItemsPerPage = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // 너비나 높이가 충분히 크게 변경되었는지 확인
    if (
      Math.abs(prevSize.width - width) > 200 ||
      Math.abs(prevSize.height - height) > 200
    ) {
      console.log("창 크기가 크게 변경되었습니다.");

      if (height < 1200 && itemsPerPage !== 12) {
        setItemsPerPage(12);
        setCurrentPage(1);
        setCurrentPageGroup(1);
      } else if (height >= 1200 && itemsPerPage !== 24) {
        setItemsPerPage(24);
        setCurrentPage(1);
        setCurrentPageGroup(1);
      }

      // 새로운 너비와 높이를 상태에 저장
      setPrevSize({ width, height });
    }
  };
  const executeSearch = () => {
    setCurrentPage(1);
    setCurrentPageGroup(1);
    const searchValue = searchInputRef.current?.value;
    filterManagerList(searchValue, company);
  };

  // 엔터 키를 눌렀을 때 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      executeSearch();
    }
  };
  const handleSearchClick = () => {
    executeSearch();
  };

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    const newCompany = event.target.value;
    setCompany(newCompany);
    setCurrentPage(1);
    setCurrentPageGroup(1);

    // 검색창의 값을 지우기
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    // 검색창이 비어있으므로, company 값만으로 필터링 수행
    filterManagerList("", newCompany);
  };

  const sortedManagerList = filteredManagerList;

  numberOfPages = Math.ceil(managerList.length / itemsPerPage);
  maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);

  const displayedManagerList = sortedManagerList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log("manager List Form 렌더링");
  const pageNumbers = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1
  ).slice(
    (currentPageGroup - 1) * pagesPerGroup, // 은 현재 페이지 그룹의 시작 페이지 번호를 계산
    currentPageGroup * pagesPerGroup // 현재 페이지 그룹의 마지막 페이지 번호
    //이는 다음 페이지 그룹의 시작 번호와 동일하므로, 실제로는 이 번호보다 하나 작은 페이지까지가 현재 페이지 그룹에 포함됩니다.
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        // flexWrap="wrap"
        // justifyContent="space-between"
        p={0}
        mx={0}
        my={0}
        sx={{}}
      >
        {companyList.length > 0 && (
          <FormControl
            variant="outlined"
            sx={{ marginLeft: 0, marginRight: 5, width: 200, minWidth: 100 }}
          >
            <InputLabel id="company-label">Company</InputLabel>
            <Select
              labelId="company-label"
              value={company}
              onChange={handleCompanyChange}
              label="Company"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {companyList.map((company) => (
                <MenuItem value={company.companyName} key={company.companyName}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label="Search user ID or name "
          variant="outlined"
          // value={searchName}
          // onChange={handleSearchNameChange}
          inputRef={searchInputRef}
          onKeyDown={handleKeyDown} // 엔터 키 감지
          sx={{ minWidth: 100 }}
        />
        <Button
          variant="outlined"
          onClick={handleSearchClick} // 검색 버튼 클릭
          sx={{ minWidth: "fit-content" }}
        >
          <SearchIcon />
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        // justifyContent="space-between"
        p={1}
        mx={8}
        my={2}
        bgcolor="background.paper"
        sx={{
          maxWidth: "100%",
          minHeight: "70vh",
          backgroundColor: grey[200],
          borderRadius: 7,
        }}
      >
        {displayedManagerList.map((manager: Manager) => (
          <ManagerCardForm manager={manager} key={manager.managerId} />
        ))}
      </Box>
      <PaginationComponent
        currentPageGroup={currentPageGroup}
        setCurrentPageGroup={setCurrentPageGroup}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        maxPageGroup={maxPageGroup}
        pageNumbers={pageNumbers}
        pagesPerGroup={pagesPerGroup}
        navigate={navigate} // navigate 함수를 prop으로 전달
        pageUrlPattern={"/manager-list/:page"}
      />
    </>
  );
};

export default ManagerListForm;
