// UserInfoPage.tsx
import { UserAllLoginDetailDataTemp } from "../../types/userTypes_temp";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PaginationComponent from "../Pagination";

interface UserDetailFormProps {
  userDetailLoginDataTemp: UserAllLoginDetailDataTemp[];
}
const UserDetailFormTemp: React.FC<UserDetailFormProps> = ({
  userDetailLoginDataTemp,
}) => {
  const navigate = useNavigate();
  const { id, page } = useParams<{ id: string; page: string }>();

  // URL에서 가져온 페이지 번호를 정수로 변환하고, 유효하지 않으면 1로 설정
  const initialPage = parseInt(page || "1", 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const pagesPerGroup = 10; //10 한페이지에 보이는 페이지버튼의 수
  const [itemsPerPage, setItemsPerPage] = useState(12);

  let numberOfPages = Math.ceil(userDetailLoginDataTemp.length / itemsPerPage);
  let maxPageGroup = Math.ceil(numberOfPages / pagesPerGroup);

  useEffect(() => {
    // URL의 페이지 번호가 변경될 때마다 현재 페이지 상태 업데이트
    setCurrentPage(initialPage);
  }, [initialPage]);

  if (!userDetailLoginDataTemp || userDetailLoginDataTemp.length === 0) {
    return <div>데이터가 없습니다.</div>;
  }
  if (!Array.isArray(userDetailLoginDataTemp)) {
    return <></>;
  }

  const filteredUserList = userDetailLoginDataTemp;

  const sortedUserList = filteredUserList;

  if (!Array.isArray(sortedUserList)) {
    return <div>잘못된 데이터 형식입니다.</div>;
  }

  const displayedUserList = sortedUserList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const pageNumbers = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1
  ).slice(
    (currentPageGroup - 1) * pagesPerGroup, // 은 현재 페이지 그룹의 시작 페이지 번호를 계산
    currentPageGroup * pagesPerGroup // 현재 페이지 그룹의 마지막 페이지 번호
    //이는 다음 페이지 그룹의 시작 번호와 동일하므로, 실제로는 이 번호보다 하나 작은 페이지까지가 현재 페이지 그룹에 포함됩니다.
  );

  const handleResetList = () => {
    navigate("/user-list-temp/1");
  };

  return (
    <>
      <Box sx={{ width: "100%", minWith: "200px" }}>
        <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Time
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  IP address
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedUserList.map(
                (user: UserAllLoginDetailDataTemp, index) => (
                  <TableRow
                    key={`${user.userId}-${user.loginDate}-${user.loginTime}-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{user.loginDate}</TableCell>
                    <TableCell align="center">{user.loginTime}</TableCell>
                    <TableCell align="center">{user.loginType}</TableCell>
                    <TableCell align="center">{user.pcIp}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          display="flex"
          // justifyContent="flex-end"
          // flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box />
          <PaginationComponent
            currentPageGroup={currentPageGroup}
            setCurrentPageGroup={setCurrentPageGroup}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPageGroup={maxPageGroup}
            pageNumbers={pageNumbers}
            pagesPerGroup={pagesPerGroup}
            navigate={navigate} // navigate 함수를 prop으로 전달
            pageUrlPattern={`/user-detail-temp/${id}/:page`}
          />

          <Button
            variant="contained"
            onClick={handleResetList}
            sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
          >
            사원 목록
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserDetailFormTemp;
