import React, { Dispatch, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import routes from "./router/routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./router/privateRoute";
import { useDispatch } from "react-redux";
import { checkLoginStatus } from "./redux/manager/managerActions";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Box, CssBaseline } from "@mui/material";
import theme from "../src/components/theme";
import ScrollToTop from "./components/ScrollToTop";
type RouteType = {
  path: string;
  component: React.FC;
  allowedRoles?: string[];
};

const App: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(checkLoginStatus()); //토큰이 존재하면 isLoggedIn true로 변경
  }, [dispatch]);

  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {/* <ThemeProvider theme={theme}> */}
      <CssBaseline />
      <Navbar />
      <ScrollToTop></ScrollToTop>
      <ToastContainer autoClose={3000} position="bottom-right" />
      <Box sx={{ flexGrow: 1, maxWidth: "100%", minHeight: "85vh" }}>
        {/* footer가 위로 안올라오게 하는 역할 */}
        <Routes>
          {(routes as RouteType[]).map((route) => {
            if (route.allowedRoles) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <PrivateRoute allowedRoles={route.allowedRoles}>
                      <route.component />
                    </PrivateRoute>
                  }
                />
              );
            } else {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              );
            }
          })}
        </Routes>
      </Box>
      <Footer />
      {/* </ThemeProvider> */}
    </div>
  );
};

export default App;
