import React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { User } from "@/types/userTypes";
import {  fetchUserInfo, updateUser } from "../../redux/user/userActions";
import Loading from "../../components/Loading";

//하단 장식
const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.kind-soft.com/">
        KINDSOFT Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
type RouteParams = {
  [key: string]: string | undefined;
};

export const UserUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const { id } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState<User | null>(null);

  useEffect(() => {
    if (id) {
      console.log("useEffect1- fetchUserInfo(id)호출 id:", id);
      dispatch(fetchUserInfo(id))
        .then((data) => {
          console.log("setIsLoading작동"); //상태 업데이트가 화면에 반영되기 전에 실행됩니다.
          setIsLoading(false); //비동기적 처리
          setFetchedData(data); // 비동기적 처리 가져온 데이터를 상태에 저장
          console.log(" useEffect1 - 전달받은 data: ", data);
        })
        .catch((error: any) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [dispatch, id]);

  //useSelect를 사용하면 또 리랜더링이되버림.
  // const fetchedManager = useSelector(
  //   (state: RootState) => state.manager.manager
  // );

  console.log("user update page렌더링");
  useEffect(() => {
    console.log("useEffect2들어옴");
    if (fetchedData) {
      console.log("useEffect2 - fetchedData:", fetchedData);
      setUserId(fetchedData.userId);
      setEin(fetchedData.ein);
      setDepartment(fetchedData.department);
      setEmail(fetchedData.email);
      setTel(fetchedData.tel);
    }
  }, [fetchedData]); // useEffect는 컴포넌트가 최초로 렌더링될 때와 fetchedManager 상태가 변경될 때마다 실행됩니다.

  //id 관련
  const [userId, setUserId] = useState("");

  //사원 이름/번호 관련

  const [ein, setEin] = useState("");
  const [einValid, setEinValid] = useState(true);
  const [einError, setEinError] = useState<string | null>(null);

  //부서 관련

  const [department, setDepartment] = useState("");
  const [departmentValid, setDepartmentValid] = useState(true);
  const [departmentError, setDepartmentError] = useState<string | null>(null);

  //이메일 관련

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState<string | null>(null);

  //전화번호 관련
  const [tel, setTel] = useState("");
  const [telValid, setTelValid] = useState(true);
  const [telError, setTelError] = useState<string | null>(null);

  //사원 번호/이름 관련 함수
  const handleEinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEin(value);
    setEinValid(value.trim() !== ""); //  비어있지 않은 경우에만 true
  };

  //부서 관련 함수
  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDepartment(value);
    setDepartmentValid(value.trim() !== ""); // 컴퍼니명이 비어있지 않은 경우에만 true
  };

  //e메일 관련 함수
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    checkEmailValidity(e.target.value);
  };
  const checkEmailValidity = (email: string) => {
    if (emailRegex.test(email)) {
      setEmailValid(true);
      setEmailError(null);
    } else {
      setEmailValid(false);
      setEmailError("이메일 형식에 맞지 않습니다.");
    }
  };

  //전화번호 관련 함수
  const telRegex = /^\d*-\d*-\d*$/; // 최소한 두 개의 '-' 가 포함되어야 함
  const handleTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTel(value);

    if (telRegex.test(value)) {
      setTelValid(true);
      setTelError(null);
    } else {
      setTelValid(false);
      setTelError("대시('-')를 포함한 휴대폰 번호를 입력해주세요");
    }
  };

  // 수정된 필드를 추적하는 상태
  const [isModified, setIsModified] = useState(false);

  // 각 입력 필드의 onChange 핸들러에서 이 상태를 업데이트
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsModified(true); // 입력이 변경되면 수정됨으로 설정
  };

  // '수정' 버튼의 활성화 여부 결정
  const isSubmitDisabled = !(
    isModified &&
    emailValid &&
    telValid &&
    einValid &&
    departmentValid
  );

  //submit 함수
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(
      data.get("userId"),
      data.get("ein"),
      data.get("department"),
      data.get("email"),
      data.get("tel")
    );
    const userData: any = {
      userId: data.get("userId") as string,
    };
    if (fetchedData) {
      if (ein !== fetchedData.ein) {
        userData.ein = ein;
      }
      if (department !== fetchedData.department) {
        userData.department = department;
      }
      if (email !== fetchedData.email) {
        userData.email = email;
      }
      if (tel !== fetchedData.tel) {
        userData.tel = tel;
      }
    }
    console.log("user-update-handleSubmit메서드 작동");
    dispatch(updateUser(userData, navigate));
  };

  if (isLoading) {
    console.log("Loading...중");
    return  <Loading/>; // 또는 로딩 스피너 등을 표시
  }

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 320 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          사원 정보 수정
        </Typography>
        <Box
          component="form"
          // noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 8 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="userId"
                label="ID"
                name="userId"
                autoComplete="id"
                value={userId}
                inputProps={{ readOnly: true }} //  필드 활성화/비활성화
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="ein"
                label="사원 이름 또는 번호"
                name="ein"
                value={ein}
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleEinChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!einError} // 에러가 있을 때 필드에 에러 표시
                helperText={einError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="department"
                label="부서명"
                name="deparment"
                value={department}
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleDepartmentChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!departmentError} // 에러가 있을 때 필드에 에러 표시
                helperText={departmentError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="이메일"
                name="email"
                value={email}
                autoComplete="email"
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleEmailChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!emailError} // 이메일 에러가 있을 때, 필드에 에러 표시
                helperText={emailError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="tel"
                label="전화번호(-포함)"
                name="tel"
                value={tel}
                autoComplete="tel"
                onChange={(e) => {
                  const inputEvent = e as React.ChangeEvent<HTMLInputElement>;
                  handleTelChange(inputEvent);
                  handleInputChange(inputEvent);
                }}
                error={!!telError} // 에러가 있을 때 필드에 에러 표시
                helperText={telError} // 에러 메시지 출력
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitDisabled}
          >
            수정
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
    // </ThemeProvider>
  );
};
