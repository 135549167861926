import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducers";
import {
  FETCH_USERLIST_FAILURE_TEMP,
  FETCH_USERLIST_REQUEST_TEMP,
  FETCH_USERLIST_SUCCESS_TEMP,
  FETCH_USER_FAILURE_TEMP,
  FETCH_USER_REQUEST_TEMP,
  FETCH_USER_SUCCESS_TEMP,
  UserTemp,
  UserActionTypes,
  UserLastLoginDataTemp,
} from "../../types/userTypes_temp"; // userTypes.ts에서 타입들을 가져옵니다.
import mainRequest from "../../api/mainRequest";


export const fetchUserListTemp = () => {
  return (dispatch: any) => {
    dispatch(fetchUserListRequestTemp());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/user/temp/list", config)
      .then((response) => {
        console.log("response.data:",response.data)
        dispatch(fetchUserListSuccessTemp(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserListFailureTemp(error.message));
      });
  };
};
export const fetchUserListRequestTemp = () => {
  return {
    type: FETCH_USERLIST_REQUEST_TEMP,
  };
};

export const fetchUserListSuccessTemp = (userList: UserLastLoginDataTemp[]) => {
  return {
    type: FETCH_USERLIST_SUCCESS_TEMP,
    payload: userList,
  };
};

export const fetchUserListFailureTemp = (error: string) => {
  return {
    type: FETCH_USERLIST_FAILURE_TEMP,
    payload: error,
  };
};
//

export const fetchUserTemp =
  (
    userId: string
  ): ThunkAction<Promise<UserTemp>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_USER_REQUEST_TEMP });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/user/temp/detail/${userId}`,
          config
        );
        console.log("fetchUser의response.data", response.data);
        dispatch({ type: FETCH_USER_SUCCESS_TEMP, payload: response.data });
        resolve(response.data);
        console.log("fetchUser액션- fetchUser 성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_USER_FAILURE_TEMP, error: err.message });
        reject(err);
      }
    });
  };



export const fetchUserInfoTemp =
  (
    userId: string
  ): ThunkAction<Promise<UserTemp>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_USER_REQUEST_TEMP });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/user/temp/detail-info/${userId}`,
          config
        );
        console.log("fetchUser의response.data", response.data);
        resolve(response.data);
        console.log("fetchUser액션- fetchUserInfo 성공");
      } catch (error) {
        const err = error as Error;
        reject(err);
      }
    });
  };


