import React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  checkManagerIdDuplication,
  signUp,
} from "../../redux/manager/managerActions";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

//하단 장식
const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.kind-soft.com/">
        KINDSOFT Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

export const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  //id 관련
  const [managerId, setManagerId] = useState("");
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [idColor, setIdColor] = useState<string>("red");
  const [idDisabled, setIdDisabled] = useState(false);
  const [isIdDuplicated, setIsIdDuplicated] = useState(false);

  //pw관련
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmValid, setConfirmValid] = useState(false);
  const [confirmError, setConfirmError] = useState<string | null>(null);

  //컴퍼니 관련

  const [company, setCompany] = useState("");
  const [companyValid, setCompanyValid] = useState(false);
  const [companyError, setCompanyError] = useState<string | null>(null);

  //사업자등록번호(CRN) 관련

  const [crn, setCrn] = useState("");
  const [crnValid, setCrnValid] = useState(false);
  const [crnError, setCrnError] = useState<string | null>(null);

  //이메일 관련

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  //전화번호 관련
  const [tel, setTel] = useState("");
  const [telValid, setTelValid] = useState(false);
  const [telError, setTelError] = useState<string | null>(null);

  //id관련 함수
  const handleManagerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 한글 및 특수문자를 제외한 영문과 숫자만 허용
    const regex = /^[A-Za-z0-9]+$/;
    if (regex.test(value) || value === "") {
      setManagerId(value);
    } else {
      setIdMessage("영문과 숫자만 사용할 수 있습니다.");
      setIdColor("red");
    }
  };
  const handleVerifyId = async () => {
    console.log("id", managerId);
    const result = await dispatch(checkManagerIdDuplication(managerId));
    console.log("result", result);
    if (result && !result.duplicated) {
      //id가 중복되지 않은 경우
      setIsIdDuplicated(false);
      setIdMessage("사용 가능합니다.");
      setIdColor("green");
      setIdDisabled(true);
    } else {
      setIsIdDuplicated(true);
      setIdMessage("ID가 중복 되었습니다.");
      setIdColor("red");
    }
  };

  //pw관련 함수
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const checkPasswordValidity = (password: string) => {
    if (passwordRegex.test(password)) {
      setPasswordValid(true);
      setPasswordError(null);
    } else {
      setPasswordValid(false);
      setPasswordError(
        "비밀번호는 영어, 숫자, 특수문자로 구성되어야 하며 8자 이상이어야 합니다."
      );
    }
  };

  const checkConfirmValidity = (password: string, confirm: string) => {
    if (password === confirm) {
      setConfirmValid(true);
      setConfirmError(null);
    } else {
      setConfirmValid(false);
      setConfirmError("비밀번호가 일치하지 않습니다.");
    }
  };

  //컴퍼니 관련 함수
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCompany(value);
    setCompanyValid(value.trim() !== ""); // 컴퍼니명이 비어있지 않은 경우에만 true
  };

  //사업자등록번호(crn) 관련 함수

  const crnRegex = /^\d{3}-\d{2}-\d{5}$/;

  const handleCrnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrn(e.target.value);
    checkCrnValidity(e.target.value);
  };
  const checkCrnValidity = (crn: string) => {
    if (crnRegex.test(crn)) {
      setCrnValid(true);
      setCrnError(null);
    } else {
      setCrnValid(false);
      setCrnError("사업자 등록 번호는 xxx-xx-xxxxx 형식으로 작성해 주세요");
    }
  };

  //e메일 관련 함수
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    checkEmailValidity(e.target.value);
  };
  const checkEmailValidity = (email: string) => {
    if (emailRegex.test(email)) {
      setEmailValid(true);
      setEmailError(null);
    } else {
      setEmailValid(false);
      setEmailError("이메일 형식에 맞지 않습니다.");
    }
  };

  //전화번호 관련 함수
  const telRegex = /^\d*-\d*-\d*$/; // 최소한 두 개의 '-' 가 포함되어야 함
  const handleTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTel(value);

    if (telRegex.test(value)) {
      setTelValid(true);
      setTelError(null);
    } else {
      setTelValid(false);
      setTelError(
        "최소 2개의 대시('-')를 포함해야 합니다. 지역번호를 입력하거나 휴대폰 번호를 입력해주세요"
      );
    }
  };

  //submit 함수
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(
      data.get("managerId"),
      data.get("password"),
      data.get("company"),
      data.get("crn"),
      data.get("email"),
      data.get("tel")
    );
    const managerData = {
      managerId: data.get("managerId") as string,
      password: data.get("password") as string,
      company: data.get("company") as string,
      crn: data.get("crn") as string,
      email: data.get("email") as string,
      tel: data.get("tel") as string,
    };
    console.log("signup-handleSubmit메서드 작동");
    dispatch(signUp(managerData, navigate));
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 320 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // position: "relative",
            marginBottom: "30px",
          }}
        >
          <Typography component="h1" variant="h5">
            회원 가입
          </Typography>
          <Box
            component="img"
            sx={{
              // position: "absolute",
              // left: "100%",
              marginLeft: "20px",
              width: 200, // 너비 설정
              // height: 65, // 높이 설정
              // objectFit: "cover", // 이미지가 박스에 맞게 조정
            }}
            alt={""}
            // src="/images/JJokomi-removebg1.png"
            // src="/images/ci2-removebg-preview.png"
            src="/images/total-removebg-preview.png"
            // src="/images/kiny1-removebg-preview.png"
          />
        </Box>
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          회원가입
        </Typography> */}
        <Box
          component="form"
          // noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 8 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                id="managerId"
                label="ID"
                name="managerId"
                autoComplete="id"
                autoFocus
                value={managerId}
                onChange={handleManagerIdChange}
                error={isIdDuplicated}
                helperText={idMessage} // 중복 체크 메시지 출력
                FormHelperTextProps={{ style: { color: idColor } }}
                inputProps={{ readOnly: idDisabled }} //  필드 활성화/비활성화
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ height: 50 }}
                onClick={handleVerifyId} // 중복 체크 함수 호출
              >
                ID 중복 확인
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="비밀번호"
                type="password"
                id="password"
                autoComplete="off"
                onChange={(e) => checkPasswordValidity(e.target.value)}
              />
              {passwordError && (
                <FormHelperText error>{passwordError}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="비밀번호 확인"
                type="password"
                id="confirmPassword"
                autoComplete="off"
                onChange={(e) => {
                  checkConfirmValidity(
                    (document.getElementById("password") as HTMLInputElement)
                      .value,
                    e.target.value
                  );
                }}
              />
              {confirmError && (
                <FormHelperText error>{confirmError}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="company"
                label="회사 또는 그룹 명"
                name="company"
                onChange={handleCompanyChange}
                error={!!companyError} // 에러가 있을 때 필드에 에러 표시
                helperText={companyError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="crn"
                label="사업자 등록 번호(-포함)"
                name="crn"
                onChange={handleCrnChange}
                error={!!crnError} // 에러가 있을 때 필드에 에러 표시
                helperText={crnError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="이메일"
                name="email"
                autoComplete="email"
                onChange={handleEmailChange}
                error={!!emailError} // 이메일 에러가 있을 때, 필드에 에러 표시
                helperText={emailError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="tel"
                label="전화번호(-포함)"
                name="tel"
                autoComplete="tel"
                onChange={handleTelChange}
                error={!!telError} // 에러가 있을 때 필드에 에러 표시
                helperText={telError} // 에러 메시지 출력
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !(
                !isIdDuplicated &&
                passwordValid &&
                confirmValid &&
                emailValid &&
                telValid &&
                crnValid &&
                companyValid
              )
            }
          >
            회원가입
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/sign-in" variant="body2">
                로그인
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
    // </ThemeProvider>
  );
};
