import OrderRegisterPage from "../pages/order/OrderRegister";

const OrderRoutes = [
  {
    path: "/order",
    component: OrderRegisterPage
  },



];

export default OrderRoutes;
