import React from "react";
import ActiveNavLink from "../../components/ActiveNavLink";
import "./AdminMenuStyle.css";
const AdminMenuPage: React.FC = () => {
  return (
    <>
      <div className="container mt-5" style={{ minWidth: "300px" }}>
        <h3 className="mb-5">ADMIN 전용</h3>
        <ul className="navbar-nav d-flex flex-row align-items-center flex-wrap">
          <li
            className="nav-item mx-4  card-style"
            style={{ width: "200px", textAlign: "center" }}
          >
            <ActiveNavLink
              to="/manager-list/1"
              activeClassName="active-link"
              className="nav-link"
            >
              <span
                className="h6"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Nanum Gothic'",
                  fontSize: "17px",
                }}
              >
                매니저 관리
              </span>
            </ActiveNavLink>
          </li>
          <li
            className="nav-item mx-4 card-style"
            style={{ width: "200px", textAlign: "center" }}
          >
            <ActiveNavLink
              to="/technical-board-list/1"
              activeClassName="active-link"
              className="nav-link"
            >
              <span
                className="h6"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Nanum Gothic'",
                  fontSize: "17px",
                }}
              >
                기술문의 게시판
              </span>
            </ActiveNavLink>
          </li>
          <li
            className="nav-item mx-4 card-style "
            style={{ width: "200px", textAlign: "center" }}
          >
            <ActiveNavLink
              to="/payment-board-list/1"
              activeClassName="active-link"
              className="nav-link"
            >
              <span
                className="h6"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Nanum Gothic'",
                  fontSize: "17px",
                }}
              >
                결제문의 게시판
              </span>
            </ActiveNavLink>
          </li>
          <li
            className="nav-item mx-4  card-style"
            style={{ width: "200px", textAlign: "center" }}
          >
            <ActiveNavLink
              to="/order"
              activeClassName="active-link"
              className="nav-link"
            >
              <span
                className="h6"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Nanum Gothic'",
                  fontSize: "17px",
                }}
              >
                다날결제 테스트
              </span>
            </ActiveNavLink>
          </li>
        </ul>
      </div>
      {/* <Grid container spacing={3} sx={{ padding: 20 ,minWidth:490}}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            ADMIN 전용
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>

            <Button variant="outlined" color="primary" sx={{ margin: 1, width: "100%" }}>
              기술 문의 게시판
            </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
      
            <Button variant="outlined" color="primary" sx={{ margin: 1, width: "100%" }}>
              결제문의 게시판
            </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
 
            <Button variant="outlined" color="primary" sx={{ margin: 1, width: "100%" }}>
              결제용 페이지(테스트)
            </Button>
        </Grid>
      </Grid> */}
    </>
  );
};

export default AdminMenuPage;
