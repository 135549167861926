import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { TechnicalBoard } from "../../types/technicalBoardTypes";

interface TechnicalBoardDetailFormProps {
  technicalBoard: TechnicalBoard;
}

const TechnicalBoardDetailForm: React.FC<TechnicalBoardDetailFormProps> = ({
  technicalBoard,
}) => {
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const fileDownloadUrl = `http://logvisor.com/images/technical-board/${technicalBoard.fileUrl}`;

  return (
    <Paper sx={{ padding: "20px", margin: "20px", minWidth: 320 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: "inline", fontWeight: "bold" ,marginLeft:5 }}
        >
          {technicalBoard.companyName} - {technicalBoard.department}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", fontWeight: "light" }}
        >
          {technicalBoard.createdTime
            ? formatDate(technicalBoard.createdTime)
            : ""}
        </Typography>
        {/* <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", marginX: 5 }}
        >
          글번호.{technicalBoard.technicalBoardId}
        </Typography> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          mt: 2,
          flexDirection: { xs: "column", lg: "row" }, // 600px 이하에서는 칼럼 방향

        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향

          }}
        >
          <Typography variant="body1" sx={{ display: "inline" , mx:2}}>
            관리자ID: {technicalBoard.managerId}
          </Typography>

          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            관리자명: {technicalBoard.managerName}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            직책: {technicalBoard.jobPosition}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
            "& > *": {
              margin: "8px 25px", // 자식 요소 간의 마진 조정
            },
          }}
        >
          <Typography variant="body1" sx={{ display: "inline" , mx:2}}>
            전화번호: {technicalBoard.tel}
          </Typography>
          <Typography variant="body1" sx={{ display: "inline", mx:2 }}>
            이메일: {technicalBoard.email}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
       
          }}
        ></Box>
      </Box>
      <Box sx={{ marginY: 10 }}>
        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word"  }}>
          {technicalBoard.inquiryDetail}
        </Typography>
      </Box>
      {technicalBoard.fileUrl && (
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ marginY: 5, fontWeight: "bold" }}>
            첨부 파일
          </Typography>
          <a
            href={fileDownloadUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            파일 다운로드
          </a>
        </Box>
      )}
    </Paper>
  );
};

export default TechnicalBoardDetailForm;
