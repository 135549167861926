import React from 'react';
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate } from "react-router-dom";
import {  logoutTest } from "../../redux/manager/managerActions";
const LoginStatusPage_text = () => {
    const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logoutTest());
        navigate("/test/sign-in");
        
    };

    return (
        <div>
            로그인상태 입니다.<br/><br/>
            <button onClick={handleLogout}>로그아웃</button>
        </div>
    );
};

export default LoginStatusPage_text;