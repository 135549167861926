// User shape 정의

export interface User {
  id?: number;
  userId:string;
  managerId?:string;
  ein:string;
  department:string;
  email: string;
  tel:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface UserUpdate {
  id?: number;
  userId:string;
  managerId?:string;
  ein?:string;
  department?:string;
  email?: string;
  tel?:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserLastLoginData{
  id?: number;
  userId:string;
  ein:string;
  companyName?:string;
  loginDate:string;
  loginTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserAllLoginDetailData{
  id?: number;
  userId:string;
  managerId?:string;
  ein:string;
  department:string;
  email: string;
  tel:string;
  loginDate:string;
  loginTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface Company{
  companyName:string;
}

export interface ErrorResponse {
  message: string;
}

export interface UserState {
  loading: boolean;
  user: User | null;
  userList: UserLastLoginData[];
  userDetailLoginData: UserAllLoginDetailData[];
  error: string | null;
}

export const FETCH_USERLIST_REQUEST = "FETCH_USERLIST_REQUEST";
export const FETCH_USERLIST_SUCCESS = "FETCH_USERLIST_SUCCESS";
export const FETCH_USERLIST_FAILURE = "FETCH_USERLIST_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const PWINIT_USER = "PWINIT_USER";
export const PWINIT_USER_SUCCESS = "PWINIT_USER_SUCCESS";
export const PWINIT_USER_FAILURE = "PWINIT_USER_FAILURE";


type FetchUserListRequestAction = {
  type: typeof FETCH_USERLIST_REQUEST;
};

type FetchUserListSuccessAction = {
  type: typeof FETCH_USERLIST_SUCCESS;
  payload: UserLastLoginData[];
};

type FetchUserListFailureAction = {
  type: typeof FETCH_USERLIST_FAILURE;
  payload: string;
};

interface FetchUserRequestAction {
  type: typeof FETCH_USER_REQUEST;
}

interface FetchUserSuccessAction {
  type: typeof FETCH_USER_SUCCESS;
  payload: UserAllLoginDetailData[];
}

interface FetchUserFailureAction {
  type: typeof FETCH_USER_FAILURE;
  error: string;
}


interface DeleteUserAction {
  type: typeof DELETE_USER;
}
interface DeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

interface DeleteUserFailtureAction {
  type: typeof DELETE_USER_FAILURE;
  error: string;
}

interface PwInitUserAction {
  type: typeof PWINIT_USER;
}
interface PwInitUserSuccessAction {
  type: typeof PWINIT_USER_SUCCESS;
  payload: string;
}

interface PwInitUserFailtureAction {
  type: typeof PWINIT_USER_FAILURE;
  error: string;
}

export type UserActionTypes =
  | FetchUserListRequestAction
  | FetchUserListSuccessAction
  | FetchUserListFailureAction
  | FetchUserRequestAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserFailtureAction
  | PwInitUserAction
  | PwInitUserSuccessAction
  | PwInitUserFailtureAction;
