import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExperienceButton from "../../components/ExperienceButton";
const VideoPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            textAlign="center"
            marginTop={5}
            marginBottom={3}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontSize: "40px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                color: "black",
                // fontStyle: "italic",
                lineHeight: "1.5",
                "@media (max-width: 800px)": {
                  fontSize: "30px", //
                },
              }}
            >
              영상으로 만나는 <span style={{ color: " #0057af" }}>LogVisor</span>
            </Typography>
            <ExperienceButton />
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
          // padding: 5,
        }}
        textAlign="center"
        marginTop={5}
        marginBottom={3}
        style={{ animation: "slide-little-up 1s ease-out" }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            marginBottom: 10,
          }}
        >
          제품 소개 영상
        </Typography>
        <Box
          sx={{
            // overflow: "hidden",
            // position: "relative",
            width: "1000px",
            height: "500px",
            "@media (max-width: 900px)": {
              width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
            },
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/MS3JMvLReog"
            style={{
              //   position: "absolute",
              //   top: 0,
              //   left: 0,
              width: "100%",
              height: "100%",
            }}
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube Video"
          />
        </Box>
      </Box>
    </>
  );
};

export default VideoPage;
