import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "@/index";
import { useSelector } from "react-redux";
import UserListForm from "../../components/user/userListForm";
import { fetchUserList } from "../../redux/user/userActions";
import { Box, useTheme } from "@mui/material";
import UserProfileComponent from "../../components/user/UserProfileComponent";

const UserListPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const userList = useSelector((state: RootState) => state.user.userList);
  const userListLength = userList.length;
  const theme = useTheme(); // 테마 훅 사용
  useEffect(() => {
    console.log("유저리스트 가져오는 useEffect");
    dispatch(fetchUserList());
  }, [dispatch]);
  console.log("userListPage렌더링");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent:"center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            // backgroundColor: "green",
            [theme.breakpoints.down(1000)]: {
              // 화면 너비가 1000px 이하일 때
              flexDirection: "column", // 방향을 세로로 변경
            },
          }}
        >
          <div className="container mt-3 " style={{ minWidth: "300px" }}>
            <UserListForm userList={userList}></UserListForm>
          </div>
          <Box
            sx={{
              // backgroundColor: "yellow",
              width: "300px",
              [theme.breakpoints.down(1000)]: {
                // 화면 너비가 1000px 이하일 때
                display: "none", // 사이드바 숨기기
              },
            }}
          >
            <div
              className=""
              style={{
                marginTop: "5rem",
              }}
            >
              <UserProfileComponent followersCount={userListLength} />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default UserListPage;
