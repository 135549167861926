import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
interface FindIdFormProps {
  findIdByEmail: (email: string) => void; // 이메일로 아이디 찾기 함수
  findIdByPhone: (phone: string) => void; // 휴대전화로 아이디 찾기 함수
}

const FindIdForm: React.FC<FindIdFormProps> = ({
  findIdByEmail,
  findIdByPhone,
}) => {
  const navigate = useNavigate();
  const [findMethod, setFindMethod] = useState("email");
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFindMethod(event.target.value);
    setInputValue("");
    setInputError("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    validateInput(event.target.value);
  };

  const validateInput = (value: string) => {
    if (findMethod === "phone") {
      const phoneRegex = /^\d*-\d*-\d*$/;
      if (!phoneRegex.test(value)) {
        setInputError(
          "최소 2개의 대시('-')를 포함해야 합니다. 지역번호를 입력하거나 휴대폰 번호를 입력해주세요"
        );
      } else {
        setInputError("");
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputError) {
      return; // 유효성 검증 실패 시, 제출을 중단
    }
    if (findMethod === "email") {
      findIdByEmail(inputValue);
    } else {
      findIdByPhone(inputValue);
    }
  };
  const handleBack = () => {
    navigate(-1); // 뒤로가기
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          ID 찾기
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">검색 방법</FormLabel>
            <RadioGroup
              row
              aria-label="findMethod"
              name="findMethod"
              value={findMethod}
              onChange={handleMethodChange}
            >
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="이메일로 찾기"
              />
              <FormControlLabel
                value="phone"
                control={<Radio />}
                label="휴대전화로 찾기"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={findMethod === "email" ? "이메일" : "휴대전화"}
            name={findMethod}
            value={inputValue}
            onChange={handleInputChange}
            error={!!inputError}
            helperText={inputError}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            ID 찾기
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: 30, mt: 5 }}
                onClick={handleBack}
              >
                뒤로 가기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default FindIdForm;
