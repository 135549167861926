import { fetchTechnicalBoardList } from "../../redux/technicalBoard/technicalBoardActions";
import TechnicalBoardListForm from "../../components/technicalBoard/technicalBoardListForm";
import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "@/index";
import { useSelector } from "react-redux";

const TechnicalBoardListPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const technicalBoardList = useSelector((state: RootState) => state.technicalBoard.technicalBoardList);

  useEffect(() => {
    dispatch(fetchTechnicalBoardList());
  }, [dispatch]);

  console.log(" TechnicalBoardListPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-0"  style={{ minWidth: "500px" }}>
        <TechnicalBoardListForm technicalBoardList={technicalBoardList}/>
      </div>
    </>
  );
};

export default TechnicalBoardListPage;
