import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  FETCH_TECHNICALBOARDLIST_FAILURE,
  FETCH_TECHNICALBOARDLIST_REQUEST,
  FETCH_TECHNICALBOARDLIST_SUCCESS,
  FETCH_TECHNICALBOARD_FAILURE,
  FETCH_TECHNICALBOARD_REQUEST,
  FETCH_TECHNICALBOARD_SUCCESS,
  TechnicalBoardActionTypes,
  TechnicalBoard,
  TechnicalBoardList,
  TechnicalBoardRegisterType,
} from "../../types/technicalBoardTypes";
import { RootState } from "../reducers";
import mainRequest from "../../api/mainRequest";
import { toast } from "react-toastify";
export const registerTechnicalBoard =
  (
    registerForm: TechnicalBoardRegisterType,
    imageFile: File | null,
    navigate: (path: string) => void
  ) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    try {
      const formData = new FormData();
      if (imageFile) {
        formData.append("image", imageFile);
      }
      formData.append(
        "registerForm",
        new Blob([JSON.stringify(registerForm)], {
          type: "application/json",
        })
      );
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await mainRequest.post(
        "/technical-board/onlymanager/register",
        formData,
        config
      );

      if (response.status !== 200) {
        throw new Error("기술 게시판 등록 실패");
      }

      const data = response.data;
      console.log(data);
      toast.success("기술 게시판에 문의가 등록되었습니다!");
      navigate("/technical-board-register");
    } catch (error) {
      console.error("기술 게시판 등록 중 오류:", error);
      toast.error("기술 게시판 등록 중 오류 발생!");
    }
  };

export const fetchTechnicalBoardList = () => {
  return (dispatch: any) => {
    dispatch(fetchTechnicalBoardListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/technical-board/onlyadmin/list", config)
      .then((response) => {
        dispatch(fetchTechnicalBoardListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTechnicalBoardListFailure(error.message));
      });
  };
};
export const fetchTechnicalBoardListRequest = () => {
  return {
    type: FETCH_TECHNICALBOARDLIST_REQUEST,
  };
};

export const fetchTechnicalBoardListSuccess = (
  technicalBoardList: TechnicalBoardList[]
) => {
  return {
    type: FETCH_TECHNICALBOARDLIST_SUCCESS,
    payload: technicalBoardList,
  };
};

export const fetchTechnicalBoardListFailure = (error: string) => {
  return {
    type: FETCH_TECHNICALBOARDLIST_FAILURE,
    payload: error,
  };
};

export const fetchTechnicalBoard =
  (
    technicalBoardId: string
  ): ThunkAction<
    Promise<TechnicalBoard>,
    RootState,
    unknown,
    TechnicalBoardActionTypes
  > =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_TECHNICALBOARD_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/technical-board/onlyadmin/detail/${technicalBoardId}`,
          config
        );
        console.log("fetchTechnicalBoard의 response.data", response.data);
        dispatch({
          type: FETCH_TECHNICALBOARD_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
        console.log("FETCH_TECHNICALBOARD액션- FETCH_TECHNICALBOARD성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_TECHNICALBOARD_FAILURE, error: err.message });
        reject(err);
      }
    });
  };
