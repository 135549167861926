import {
  ManagerState,
  ManagerActionTypes,
  SIGN_IN,
  LOGOUT,
  MANAGER_INFO,
  LOGIN_SUCCESS,
  CHECK_MANAGER_START,
  CHECK_MANAGER_SUCCESS,
  CHECK_MANAGER_FAILURE,
  FETCH_MANAGERLIST_REQUEST,
  FETCH_MANAGERLIST_SUCCESS,
  FETCH_MANAGERLIST_FAILURE,
  DELETE_MANAGER,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  FETCH_MANAGER_REQUEST,
  FETCH_MANAGER_SUCCESS,
  FETCH_MANAGER_FAILURE,
  PWINIT_MANAGER_FAILURE,
  PWINIT_MANAGER_SUCCESS,
  PWINIT_MANAGER,
} from "../../types/managerTypes";

// Initial State
const initialState: ManagerState = {
  loading: false,
  isLoggedIn: false,
  checkRole: null,
  manager: null,
  managerList: [],
  isIdDuplicated: false,
  isLoading: true, // 초기 상태는 로딩 중
  error: null,
};

// Reducer
export const managerReducer = (
  state = initialState,
  action: ManagerActionTypes
): ManagerState => {
  switch (action.type) {
    case SIGN_IN: // Handle SIGN_IN action
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn || false,
        manager: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        manager: null,
        checkRole: null
      };
    // case MANAGER_INFO:
    //   return {
    //     ...state,
    //     manager: action.payload,
    //   };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case CHECK_MANAGER_START:
      return { ...state, isLoading: true };
    case CHECK_MANAGER_SUCCESS:
      return { ...state, checkRole: action.payload, manager:action.payload, isLoading: false };
    case CHECK_MANAGER_FAILURE:
      return { ...state, isLoading: false };

    case FETCH_MANAGERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MANAGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        managerList: action.payload,
        error: "",
      };
    case FETCH_MANAGERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        managerList: [],
        error: action.payload,
      };
    case DELETE_MANAGER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        managerList: state.managerList.filter(
          (manager) => manager.managerId !== action.payload
        ), // 삭제된 매니저 제거
      };
    case DELETE_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_MANAGER_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_MANAGER_SUCCESS:
      return { ...state, loading: false, manager: action.payload };
    case FETCH_MANAGER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case PWINIT_MANAGER:
      return {
        ...state,
        loading: true,
      };
    case PWINIT_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case PWINIT_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
