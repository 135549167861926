import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {  RootState } from "@/index";
import { useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PaymentBoardDetailForm from "../../components/paymentBoard/paymentBoardListDetailForm";
import { fetchPaymentBoard } from "../../redux/paymentBoard/paymentBoardActions";

type RouteParams = {
  [key: string]: string | undefined;
};

const PaymentBoardDetailPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<RouteParams>();
  const paymentBoardId = String(id);

  const { paymentBoard, loading, error } = useSelector(
    (state: RootState) => state.paymentBoard
  );
  useEffect(() => {
    console.log("paymentBoardId:", paymentBoardId);
    if (paymentBoardId && paymentBoardId !== "") {
      dispatch(fetchPaymentBoard(paymentBoardId));
    }
  }, [dispatch, paymentBoardId]);

  console.log("PaymentBoardDetailpage 렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!paymentBoard) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <PaymentBoardDetailForm
        paymentBoard={paymentBoard}
      ></PaymentBoardDetailForm>
    </div>
  );
};

export default PaymentBoardDetailPage;
