import AdminMenuPage from "../pages/admin/AdminMenuPage";
import MainPage from "../pages/main/MainPage";


const mainRoutes = [
  {
    path: "/",
    component: MainPage,
  },
  {
    path: "/admin",
    component: AdminMenuPage,
    allowedRoles: ["1"],
  },




];

export default mainRoutes;
