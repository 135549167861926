import React, { useEffect, Dispatch, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkManager, logout } from "../redux/manager/managerActions";
import { AppDispatch } from "..";
import { LOGOUT } from "../types/managerTypes";
import Loading from "../components/Loading";

const PrivateRoute: React.FC<any> = ({ children, allowedRoles }) => {
  const dispatch1: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const manager = useSelector((state: any) => state.manager.checkRole);
  //특정 manager정보를 가져오는거랑 접속한 아이디(ex admin) 의 정보가 다를 수 있어서..
  // const isLoading = useSelector((state: any) => state.manager.isLoading);
  const [isLoading, setIsLoading] = useState(true); // useState를 사용하여 isLoading 상태 관리

  useEffect(() => {
    setIsLoading(true); // 액션 디스패치 전에 isLoading을 true로 설정
    console.log("privateRoute의 useEffect에진입");
    dispatch1(checkManager())
      .then(() => {
        setIsLoading(false); // 액션 처리 완료 후 isLoading을 false로 설정
      })
      .catch(() => {
        setIsLoading(false); // 에러 발생 시 isLoading을 false로 설정
        dispatch1({ type: LOGOUT });
        localStorage.removeItem("token");
        console.log("sign-in으로 이동해봅니다.")
        navigate(`/sign-in`);
      });
  }, [dispatch1]);

  // useEffect(() => {
  //   // checkUser 액션을 디스패치
  //   console.log("privateRoute의 useEffect에진입");
  //   dispatch(checkManager());
  // }, [dispatch]);

  console.log("privateRoute렌더링");
  if (isLoading) {
    console.log("private-Route로딩중 loading...");

    return <Loading/>;
  } //여기서 첫렌더링떄 걸려야되고, useEffect를 통해 가져온 데이터가 manager에 담길떄
  //두번째 렌더링이 일어나서 접속이 가능하게됨.

  if (!manager) {
    //manager가 없을때(비회원일때)
    console.log("privateRoute- manager : ", manager);
    console.log("로그인이 필요합니다.");
    return <Navigate to="/sign-in" replace />; //repalce넣어서 뒤로가기안되는 문제 해결
  } else if (allowedRoles && !allowedRoles.includes(manager.userCode)) {
    //회원이긴 한데 권한이 없는 경우.
    console.log("allowedRoles", allowedRoles);
    console.log(
      "allowedRoles.includes(manager.userCode)",
      allowedRoles.includes(manager.userCode)
    );
    console.log("회원이긴 한데 권한이 없는 경우 - manager", manager);
    return (
      <div
        style={{ fontSize: "30px", fontWeight: "bold", textAlign: "center" }}
      >
        Access Denied
      </div>
    );
  }

  return children;
};

export default PrivateRoute;
