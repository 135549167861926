import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "@/index";
import { useSelector } from "react-redux";
import ManagerListForm from "../../components/admin/managerListForm";
import { fetchManagerList } from "../../redux/manager/managerActions";

const ManagerListPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const managerList = useSelector(
    (state: RootState) => state.manager.managerList
  );
  const managerListLength = managerList.length;
  // const theme = useTheme(); // 테마 훅 사용
  useEffect(() => {
    dispatch(fetchManagerList());
  }, [dispatch]);
  console.log("ManagerListPage 렌더링");
  console.log("useSelect로 가져온 managerList:", managerList);
  return (
    <>
      <div className="container mt-5" style={{ minWidth: "400px" }}>
        <ManagerListForm managerList={managerList}></ManagerListForm>
      </div>
    </>
  );
};

export default ManagerListPage;
